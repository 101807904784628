import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import LoaderStyle from "./LoaderStyle";
const Loader = () => {
  const antIcon = (
    <LoaderStyle>
      <LoadingOutlined
        style={{
          fontSize: 50,
        }}
        spin
      />
    </LoaderStyle>
  );
  return <Spin indicator={antIcon} />;
  // return <div className="loader"></div>;
};

export default Loader;
