import styled from "styled-components";
import { PrimaryColor } from "../../Components/GlobalStyle";

const CreditCardsStyle = styled.div`
  max-width: 600px;
  margin: 0 auto;
  .credit-card {
    border: 1px solid ${PrimaryColor};
    padding: 10px;
    margin-top: 14px;
    border-radius: 7px;
  }
`;

export default CreditCardsStyle;
