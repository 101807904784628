import { Container } from "react-bootstrap";
import { PrimaryHeading } from "../../../Components/GlobalStyle";
import TermsAndConditionsStyle from "./style";

const TermsAndConditions = () => {
  return (
    <TermsAndConditionsStyle>
      <Container>
        <PrimaryHeading>Terms and conditions</PrimaryHeading>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit ducimus
          vero iusto reprehenderit ex consequatur dicta voluptatem reiciendis
          officiis numquam sunt ea dolorum esse quas exercitationem, doloremque,
          repellat sapiente? Molestias quidem deserunt officia voluptas expedita
          perferendis consectetur commodi pariatur doloremque velit. Suscipit at
          neque, incidunt dolorum error accusantium praesentium perspiciatis
          alias est obcaecati ratione quo, sunt omnis nemo consequatur officiis
          quibusdam, mollitia tempora architecto ipsa laborum! Saepe id, quia
          laborum perspiciatis consectetur harum consequuntur quibusdam esse
          soluta. Id eligendi, minus incidunt est excepturi aspernatur
          perferendis commodi similique autem quibusdam rerum a laborum aliquid
          quas fuga nesciunt voluptates consequuntur dolore, deleniti fugiat
          dolores numquam maiores ullam eveniet. Ipsum unde tempore quam
          possimus at laborum qui autem quaerat nam laboriosam expedita facere
          accusantium, ea, architecto tempora optio labore doloremque eveniet ab
          placeat quasi nihil nemo saepe iste. Repellat adipisci fugit molestias
          culpa fugiat molestiae exercitationem necessitatibus neque eum quidem
          et natus, ratione, illum a laboriosam.
        </p>
      </Container>
    </TermsAndConditionsStyle>
  );
};

export default TermsAndConditions;
