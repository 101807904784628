import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/CustomButton";
import RegisterStyle from "./style";
import { Link, useNavigate } from "react-router-dom";
import { MainHeading, PrimaryColor } from "../../Components/GlobalStyle";
import { Helmet } from "react-helmet";
import { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Base_Url, Register_User, send_Otp } from "../../utils/api_urls";
import { toast } from "react-toastify";
import CustomInputNumber from "../../Components/CustomInputNumber/InputNumber";
import { ContextApiData } from "../../utils/CreateContext";

type registerValuesType = {
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  password: string;
  terms?: boolean;
  iamRobot?: boolean;
  // confirmPassword: string;
};

const initialValues: registerValuesType = {
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
  password: "",
  terms: false,
  // iamRobot: false,
  // confirmPassword: "",
};

const validationSchema = Yup.object({
  first_name: Yup.string().required("First Name is Required"),
  last_name: Yup.string().required("Last Name is Required"),
  mobile: Yup.string().required("mobile number is required"),
  email: Yup.string()
    .required("email is required!")
    .email("Must be Valid Email"),
  password: Yup.string()
    .min(8, "Password Should Contains at least 8 Characters")
    .required("Password is required!")
    .matches(/^(\S+$)/g, "email cannot contain blankspaces"),
  terms: Yup.boolean().oneOf([true], "Please accept terms and conditions !"),
  iamRobot: Yup.boolean().oneOf([true], "please solve captcha!"),
  // confirmPassword: Yup.string().when("password", {
  //   is: (val: any) => (val && val.length > 0 ? true : false),
  //   then: Yup.string().oneOf(
  //     [Yup.ref("password")],
  //     "Both password need to be the same"
  //   ),
  // }),
});

const Register = () => {
  const navigate = useNavigate();
  const { setMobileNumber } = useContext(ContextApiData);
  const registerToastId = useRef<any>(null);
  const registerPhoneId = useRef<any>(null);
  const { setIsLogin } = useContext(ContextApiData);

  // register handler promise
  const registerUserHandler = async (data: any) => {
    const { data: response } = await axios.post(Base_Url + Register_User, data);
    return response.data;
  };
  // register user mutation
  const { mutateAsync: registerUserMutate, isLoading }: any = useMutation(
    registerUserHandler,
    {
      onSuccess: (data) => {
        console.log({ data }, "data in register success");
        localStorage.setItem("litgalaxy:token", JSON.stringify(data));
        setIsLogin(data);
        registerToastId.current = toast("registered successfully", {
          type: "success",
          autoClose: 1000,
        });
        navigate("/verify-otp");
      },
      onError: (error: any) => {
        console.log(error.response.data, "data in error");
        // if(error.response.data.message)
        if (error.response.data.message) {
          registerToastId.current = toast(error.response.data.message, {
            type: "error",
            autoClose: false,
          });
        } else {
          registerToastId.current = toast("something went wrong", {
            type: "error",
            autoClose: false,
          });
        }
      },
    }
  );

  // Verify Otp Promise
  const registerPhoneHandler: any = async (data: any) => {
    registerPhoneId.current = toast("register phone...", {
      type: "info",
    });
    const { data: response } = await axios.post(Base_Url + send_Otp, data);
    return response.data;
  };
  // register user mutation for user registeration
  const { mutate: regiserPhoneMutation }: any = useMutation(
    registerPhoneHandler,
    {
      onSuccess: () => {
        toast.update(registerPhoneId.current, {
          render: "Phone Registered Successfully",
          type: "success",
          autoClose: 1000,
        });
        navigate("/verify-otp");
        // setIsModalVisible(true);
      },
      onError: (data: any) => {
        console.log({ data });
        toast.update(registerPhoneId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
    }
  );

  const onSubmit = async (values: registerValuesType) => {
    delete values.terms;
    delete values.iamRobot;
    const updatedMobileValue = { mobile: "+" + values.mobile };
    setMobileNumber(values.mobile);
    const allValues = { ...values, mobile: updatedMobileValue.mobile };
    setMobileNumber(updatedMobileValue.mobile);
    await registerUserMutate(allValues);
    regiserPhoneMutation(updatedMobileValue);
  };
  // if (isLoading) return <p>Loading...</p>;

  // if (error) return <p>An error has occurred: + {error.message}</p>;

  return (
    <>
      <Helmet>
        <title>Create User</title>
      </Helmet>

      <RegisterStyle>
        <div className="inner-container-card">
          <MainHeading>Register</MainHeading>
          <div className="inner-container-card-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <div className="login-input-fields">
                      <FormControl
                        control="input"
                        type="text"
                        name="first_name"
                        border="1px solid #EFEFF4"
                        placeholder="First Name"
                        className={
                          formik.errors.first_name && formik.touched.first_name
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                      <div>
                        <FormControl
                          control="input"
                          l
                          type="text"
                          name="last_name"
                          border="1px solid #EFEFF4"
                          placeholder="Last Name"
                          className={
                            formik.errors.last_name && formik.touched.last_name
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </div>
                      <div className="mt-2">
                        <div className="login-input-fields-field">
                          <CustomInputNumber
                            value={formik.values.mobile}
                            formik={formik}
                            placeholder="e.g 12345678"
                          />
                        </div>
                        <div className="mt-2">
                          {formik.errors.mobile && formik.touched.mobile && (
                            <p className="error-text">{formik.errors.mobile}</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <FormControl
                          control="input"
                          type="text"
                          name="email"
                          border="1px solid #EFEFF4"
                          placeholder="Enter Email"
                          className={
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </div>
                      <div className="login-input-fields-field mt-2">
                        <FormControl
                          control="password"
                          type="text"
                          name="password"
                          border="1px solid #EFEFF4"
                          placeholder="Password"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "is-invalid"
                              : "customPasswordInput"
                          }
                        />
                      </div>
                      {/* <div className="login-input-fields-field mt-2">
                        <FormControl
                          control="password"
                          type="text"
                          name="confirmPassword"
                          border="1px solid #EFEFF4"
                          placeholder="Password"
                          className={
                            formik.errors.confirmPassword &&
                            formik.touched.confirmPassword
                              ? "is-invalid"
                              : "customPasswordInput"
                          }
                        />                      
                      </div> */}
                      <div className="my-4">
                        <ReCAPTCHA
                          sitekey="6LckwgkkAAAAAGzTkmtBsb8DgDtApfTRHw33naiF"
                          onChange={() =>
                            formik.setFieldValue("iamRobot", true)
                          }
                        />
                      </div>
                      {formik.errors.iamRobot && formik.touched.iamRobot && (
                        <p className="error-text">{formik.errors.iamRobot}</p>
                      )}
                      <div className="d-flex mb-2 mt-2 align-items-center">
                        <input
                          value={formik.values.terms as any}
                          onChange={() =>
                            formik.setFieldValue("terms", !formik.values.terms)
                          }
                          type="checkbox"
                          name="terms"
                          id="terms"
                        />
                        {/* <p className="remember-password-text mb-0 ms-2">
                          Remember me
                        </p> */}
                        <label className="terms-text mb-0 ms-2" htmlFor="terms">
                          By signing up you have agreed
                          <Link to="/terms" className="text-decoration-none">
                            <span className="fw-bold ms-1">
                              Terms of Use & Privacy Policy
                            </span>
                          </Link>
                        </label>
                      </div>
                      {formik.errors.terms && formik.touched.terms && (
                        <p className="error-text">{formik.errors.terms}</p>
                      )}
                      <div className="mt-2">
                        <CustomButton
                          bgcolor={PrimaryColor}
                          color="white"
                          padding="11px 8px"
                          width="100%"
                          type="submit"
                          title="Register"
                          fontSize="16px"
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {/* <AuthenticationText
            link="/"
            title="Already registered?"
            linkText="Log in here"
            margin="10px 0 0 0"
          /> */}
        </div>
      </RegisterStyle>
    </>
  );
};

export default Register;
