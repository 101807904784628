import { Form } from "antd";
import { ErrorMessage, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import FormController from "../../../Components/FormControl";
import { PrimaryColor, TertiaryHeading } from "../../../Components/GlobalStyle";
import CreateShippingAddressesStyle from "./style";
import rigtSideImg from "../../../assets/litgalaxyicons/main-img.jpg";
import CustomInputNumber from "../../../Components/CustomInputNumber/InputNumber";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Base_Url,
  create_cart,
  shipping_addresses_url,
} from "../../../utils/api_urls";
import { useMutation } from "react-query";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { useContext, useRef } from "react";
import { ContextApiData } from "../../../utils/CreateContext";

const CreateShippingAddress = () => {
  const createShippingAddressToastId = useRef<any>(null);
  const tickeToastId = useRef<any>(null);
  const { userTickets, shippingAddressLength } = useContext(ContextApiData);

  type CreateShippingAddressesTypes = {
    first_name: string;
    last_name: string;
    address_line_1: string;
    phone_number: string;
    aptNumber?: string;
  };

  const navigate = useNavigate();

  const initialValues = {
    first_name: "",
    last_name: "",
    address_line_1: "",
    phone_number: "",
    aptNumber: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address_line_1: Yup.string().required("Address is required"),
    phone_number: Yup.string().required("Phone is required"),
    aptNumber: Yup.string().required("Apartment No. is required"),
  });

  // create cart promise
  const createCartHandler = async (data: any) => {
    tickeToastId.current = toast("cart adding...", {
      type: "info",
    });
    const { data: response } = await axios.post(Base_Url + create_cart, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // create cart mutation
  const { mutate: createTicketsMutation } = useMutation(createCartHandler, {
    onSuccess: () => {
      toast.update(tickeToastId.current, {
        render: "cart added successfully",
        type: "success",
        autoClose: 1000,
      });
      navigate("/user-cart");
    },
    onError: ({ response: { data } }: any) => {
      toast.update(tickeToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  // create shopping address promise
  const shippingAddressHandler = async (data: any) => {
    createShippingAddressToastId.current = toast("sending address...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + shipping_addresses_url,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // create shopping address mutation
  const { mutateAsync } = useMutation(shippingAddressHandler, {
    onSuccess: (data) => {
      toast.update(createShippingAddressToastId.current, {
        render: "address sended successfully",
        type: "success",
        autoClose: 1000,
      });
      console.log(data);
    },
    onError: ({ response: { data } }: any) => {
      console.log({ data });
      if (data.message === "Unauthenticated.") {
        toast.update(createShippingAddressToastId.current, {
          render: "unauthnticated",
          type: "error",
          autoClose: 1000,
        });
      } else {
        toast.update(createShippingAddressToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      }
    },
  });

  const onSubmit = async (values: CreateShippingAddressesTypes) => {
    delete values.aptNumber;
    const newValues = {
      email: "shafqat1234@test.com",
      address_line_2: "Drive",
      country_id: 1,
      state_id: 1,
      city_id: 1,
      postal_code: "3045",
      lat: 37.4281350802915,
      lng: -122.0792542197085,
      created_at: "",
      updated_at: "",
      ...values,
    };
    await mutateAsync(newValues);
    console.log({ shippingAddressLength });
    if (shippingAddressLength === 0 && userTickets.cartItems.length !== 0) {
      createTicketsMutation(userTickets);
      navigate("/user-cart");
      return;
    }
    navigate("/user-shipping-addresses");
  };

  return (
    <CreateShippingAddressesStyle className="row align-items-center">
      <Col md={8}>
        <TertiaryHeading>Personal Info</TertiaryHeading>
        <div className="inner-container mt-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form
                  style={{
                    height: "100%",
                  }}
                  name="basic"
                  onFinish={formik.handleSubmit}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col md={6} className="mt-2">
                      <FormController
                        control="input"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        padding="12px"
                        label="First Name"
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={
                          formik.errors.first_name && formik.touched.first_name
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                    <Col md={6} className="mt-2">
                      <FormController
                        control="input"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        padding="12px"
                        label="Last Name"
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={
                          formik.errors.last_name && formik.touched.last_name
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                  </Row>
                  <TertiaryHeading className="mt-3">
                    Main Shipping Address
                  </TertiaryHeading>
                  <div className="inner-container mt-3">
                    <div className="mt-2">
                      <FormController
                        control="input"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        padding="12px"
                        label="Enter Complete Address"
                        type="text"
                        name="address_line_1"
                        placeholder="Enter Complete Address"
                        className={
                          formik.errors.address_line_1 &&
                          formik.touched.address_line_1
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </div>
                    <Row>
                      <Col md={6} className="mt-2">
                        <FormController
                          control="input"
                          fontFamily="EnnVisionsMedium"
                          labelFamily="EnnVisionsMedium"
                          padding="12px"
                          label="Apt no./unit"
                          type="number"
                          name="aptNumber"
                          placeholder="Apt no./unit"
                          className={
                            formik.errors.aptNumber && formik.touched.aptNumber
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                      <Col md={6} className="mt-2">
                        <CustomInputNumber
                          padding="7px"
                          value={formik.values.phone_number}
                          formik={formik}
                          showLabel={true}
                          name="phone_number"
                          placeholder="Enter Phone ..."
                          marginTop="4px"
                          label="Phone Number"
                        />
                        {/* <ErrorMessage name="phone" component={<p></p>}/> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-2 d-flex justify-content-center justify-content-sm-end">
                    <CustomButton
                      bgcolor={PrimaryColor}
                      color="white"
                      padding="8px 8px"
                      width="250px"
                      type="submit"
                      title="SAVE ADDRESS"
                      margin="auto"
                      fontFamily="EnnVisionsMedium"
                      fontSize="16px"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Col>
      <Col md={4} className=" my-3 mx-auto d-flex">
        <figure className="mb-3 mx-auto">
          <img src={rigtSideImg} alt="" />
        </figure>
      </Col>
    </CreateShippingAddressesStyle>
  );
};

export default CreateShippingAddress;
