import styled from "styled-components";
import LiveUpdate from "./LiveUpdate";

const LiveUpdateStyle = styled.div`
  .liveUpdate__container {
    height: auto;
    width: 100%;
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    padding: 5px;
  }
  .likes {
    font-family: "EnnVisionsMedium";
  }

  .liveUpdate__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
  .liveUpdate__heading {
    text-align: left;
    font-size: 16px;
    font-family: "EnnVisionsbold";
    color: #232837;
  }
  .liveUpdate__subheading {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisions";
    color: #6f7177;
  }
  .LiveUpdate__date {
    text-align: right;
    font-size: 16px;
    font-family: "Uber Movemedium";
    color: #464e5f;
  }
`;

export default LiveUpdateStyle;
