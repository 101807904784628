import styled from "styled-components";
import {
  lightGrey,
  lightGrey2,
  maxWidthDevice,
  PrimaryColor,
  pureDark,
  secondaryDark3,
  whiteColor,
} from "../../GlobalStyle";

type EarlyBirdtTicketsStyleProps = {
  selected: boolean;
};

const EarlyBirdtTicketsStyle = styled.div<EarlyBirdtTicketsStyleProps>`
  text-align: left;
  width: 100%;
  background: ${whiteColor};
  border: ${({ selected }) =>
    `1px solid ${selected ? PrimaryColor : lightGrey2}`};
  border-radius: 4px;
  img {
    width: 25px;
    height: 25px;
    border-radius: 4px;
  }
  hr {
    color: ${lightGrey};
    margin: 8px 0;
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  .Earlybirdtickets__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media (max-width: 768px) {
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }
  }
  .Earlybirdtickets__stage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    @media (max-width: 768px) {
      text-align: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .Earlybirdtickets__heading {
    font-family: "EnnVisionsMedium";
    font-size: 16px;
    letter-spacing: 0.1px;
    color: ${pureDark};
    margin-right: auto;

    @media (${maxWidthDevice.tabletL}) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      text-align: center;
      margin: 0;
    }
  }
  .Earlybirdtickets__quatity {
    font-size: 16px;
    font-family: "EnnVisionsMedium";
    letter-spacing: 0.1px;
    color: ${secondaryDark3};
  }
  .Earlybirdtickets__price {
    text-align: left;
    font-size: 19px;
    font-family: "EnnVisionsMedium";
    letter-spacing: 0.11px;
    color: ${lightGrey2};
    @media (max-width: 768px) {
      text-align: center;
      font-size: 16px;
    }
  }
  .Earlybirdtickets__subheading {
    font-size: 14px;
    font-family: "EnnVisions";
    color: ${lightGrey2};
  }
  .Earlybirdtickets__tertiaryheading {
    font-size: 15px;
    font-family: "EnnVisionsMedium";
    color: ${lightGrey2};
    padding: 10px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
`;
export default EarlyBirdtTicketsStyle;
