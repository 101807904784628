import styled from "styled-components";
import {
  darkbluelight,
  pureDark,
  tertiaryGrey6,
  whiteColor,
} from "../../GlobalStyle";

const ShippingAddressCardStyle = styled.div`
  background: ${whiteColor};
  border: 0.5px solid ${tertiaryGrey6};
  border-radius: 2px;
  padding: 10px;
  .shipping-address-card {
    text-align: left;

    &__heading {
      font-size: 18px;
      font-family: "EnnVisionsMedium";
      color: ${darkbluelight};
    }
    &__name {
      font-size: 16px;
      font-family: "EnnVisionsMedium";
      color: ${pureDark};
    }
    &__email,
    &__address {
      font-family: "EnnVisions";
      font-size: 16px;
      color: ${pureDark};
    }
    &__phone {
      font-family: "EnnVisionsMedium";
      font-size: 16px;
      color: ${pureDark};
    }
    &__address {
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

export default ShippingAddressCardStyle;
