import styled from "styled-components";
import { darkbluelight, lightGrey2, PrimaryColor } from "../../GlobalStyle";

const HeroStyle = styled.div`
  .hero__image-container {
    max-width: 656px;
    width: 100%;
    height: 397px;
    .hero__image {
      border-radius: 7px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero__heading {
    font-family: "EnnVisionsBold";
    font-size: 28px;
    font-weight: bold;
    color: ${darkbluelight};
    text-transform: capitalize;
    @media (max-width: 1024px) {
      font-size: 25px;
    }
    @media (max-width: 768px) {
      font-size: 22px;
    }
    @media (max-width: 580px) {
      font-size: 20px;
    }
    @media (max-width: 380px) {
      font-size: 18px;
    }
  }
  .hero__subheading {
    font-family: "EnnVisionsMedium";
    font-size: 16px;
    color: ${PrimaryColor};
  }
  .hero__details {
    font-family: "EnnVisions";
    font-size: 16px;
    letter-spacing: 0.1px;
    color: ${lightGrey2};
  }
`;
export default HeroStyle;
