import styled from "styled-components";
import {
  darkbluelight,
  darkbluelight2,
  lightGrey,
  whiteColor,
} from "../../GlobalStyle";

const PaymenySummaryStyle = styled.div`
  .paymeny-summary {
    &__container {
      background: ${whiteColor};
      box-shadow: 0 2rem 2rem rgba(255, 255, 255, 0.7);
      border: 0.5px solid ${lightGrey};
      padding: 10px 5px;
    }
    &__payment-name {
      font-size: 14px;
      font-family: "EnnVisionsMedium";
      color: ${darkbluelight2};
    }
    &__payment-price-cad,
    &__price-cad,
    &__grand-total {
      font-family: "EnnVisionsMedium";
      font-size: 16px;
      color: ${darkbluelight};
    }
  }
`;
export default PaymenySummaryStyle;
