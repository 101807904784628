import styled from "styled-components";
import {
  darkbluelight,
  lightGrey2,
  maxWidthDevice,
  pureDark,
} from "../../GlobalStyle";

const AbouteventStyle = styled.div`
  text-align: left;
  line-break: auto;
  line-height: 1.2;
  .about__subheading {
    font-family: "EnnVisionsMedium";
    font-size: 14px;
    font-style: medium;
    letter-spacing: 0.08px;
    color: ${darkbluelight};
  }
  .about__tertiaryheading {
    color: ${darkbluelight};
  }
  .about__secondaryheading {
    color: ${lightGrey2};
  }
  .about__primaryheading {
    color: ${pureDark};
  }
  .about__hours-timing {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    width: 50%;
    @media ${maxWidthDevice.laptopL} {
      width: 50%;
    }
    @media ${maxWidthDevice.laptop} {
      width: 70%;
    }
    @media ${maxWidthDevice.tablet} {
      width: 80%;
    }
    @media ${maxWidthDevice.mobilexL} {
      width: 100%;
    }
  }
`;
export default AbouteventStyle;
