import TicketInfoStyle from "./style";
import editicon from "../../../assets/litgalaxyicons/ic_edit.svg";
import { useNavigate, useParams } from "react-router-dom";
import { cart_item_shipping_address } from "../../../Screens/litgalaxy/UserCart/UserCart";
import { DeleteOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Base_Url, delete_single_cart_item } from "../../../utils/api_urls";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { useMutation, useQueryClient } from "react-query";
import CustomModal from "../../Modal/CustomModal";
import DeleteConfirmation from "../../DeleteConfirmation/DeleteConfirmation";

type ticketInfoCartProps = {
  isEdit?: boolean;
  isDelete?: boolean;
  id?: number | string;
  cart_item_data: cart_item_shipping_address;
  name: string;
  description: string;
  price: number;
};

const Ticketinfo = ({
  isEdit = true,
  isDelete = true,
  price,
  name,
  id,
  description,
  cart_item_data: {
    id: cartItemId,
    shippingAddress,
    // shippingAddress: {
    //   id: addressId,
    //   first_name,
    //   last_name,
    //   address_line_1,
    //   email,
    //   phone_number,
    // },
    wristband: { id: itemId },
  },
}: ticketInfoCartProps) => {
  const navigate = useNavigate();
  const singleCartItemToastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const [isShowModal, setIsShowModal] = useState(false);
  const { singleCartId } = useParams();

  // delete single cart
  // delete single cart item promise
  const deleteSingleeCartHandler = async (id: number | string) => {
    singleCartItemToastId.current = toast("delete cart...", {
      type: "info",
    });
    const { data: response } = await axios.get(
      Base_Url + delete_single_cart_item + id + "/delete",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // delete single cart item mutation
  const { mutateAsync: deleteSingleCartMutation } = useMutation(
    deleteSingleeCartHandler,
    {
      onSuccess: () => {
        toast.update(singleCartItemToastId.current, {
          render: "deleted cart successfully",
          type: "success",
          autoClose: 1000,
        });
        queryClient.invalidateQueries("user-cart-items");
      },
      onError: ({ response: { data } }: any) => {
        toast.update(singleCartItemToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
    }
  );

  // delete cart item in delete modal

  const deleteSingleCart = () => {
    deleteSingleCartMutation(singleCartId!);
    queryClient.invalidateQueries("user-cart-items");
    navigate(-1);
  };

  return (
    <TicketInfoStyle className="mt-2 mb-3" key={cartItemId}>
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
      >
        <DeleteConfirmation
          setIsShowModal={setIsShowModal}
          title="Do you want to delete item?"
          handleConfirmation={deleteSingleCart}
        />
      </CustomModal>
      <div className="ticket__purchase">
        <div className="d-flex align-items-center justify-content-between mb-">
          <h6 className="mb-0 ticket__id-num">Ticket id: #{cartItemId}</h6>
          <p className="mb-0 ticket__price">
            ${price.toLocaleString("en-US")} CAD
          </p>
        </div>
        <h3 className="ticket__category my-3">{name}</h3>
        <p className="ticket__date">
          <span>{description}</span>
          {/* <span>: June 01, 2023</span> */}
        </p>
      </div>
      {shippingAddress ? (
        <div className="ticket__ownership">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="mb-0 ticket__owner-name">
              {shippingAddress.first_name} {shippingAddress.last_name}
            </h6>
            {isEdit && isDelete && (
              <div className="d-flex align-items-center cursor-pointer">
                <DeleteOutlined
                  className="me-3 mb-1 fs-4"
                  onClick={() => {
                    setIsShowModal(true);
                    navigate(`/user-cart/${id}`);
                  }}
                />
                <figure
                  className="mb-0 cursor-pointer"
                  onClick={() => {
                    if (shippingAddress.id) {
                      navigate(`/user-shipping-addresses/update/${cartItemId}`);
                    }
                  }}
                >
                  <img src={editicon} alt="image of edit" />
                </figure>
              </div>
            )}
          </div>
          <p className="ticket-owner-address mb-0 mt-1">
            <span className="fw-bold me-2">Address :</span>
            {shippingAddress.address_line_1}
          </p>
          <p className="ticket-owner-email mb-0">{shippingAddress.email}</p>
          <p className="ticket-owner-phone-number my-3">
            {shippingAddress.phone_number}
          </p>
        </div>
      ) : (
        <div className="ticket__ownership">
          <div className="d-flex align-items-center justify-content-between">
            <h6>No Data</h6>
          </div>
        </div>
      )}
    </TicketInfoStyle>
  );
};

export default Ticketinfo;
