import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  ColoredButton,
  lightBlue3,
  OrangeColor,
  PrimaryHeading,
} from "../../GlobalStyle";
import UserTicketsStyle from "./style";

// user dashboard types

export type userDashboardTypes = {
  id: number;
  item_price: number;
  order_id: number;
  shipping_address_id: number;
  status: string;
  updated_at: string;
  wristband: {
    balance: number;
    category_id: number;
    id: number;
    nfc_unique_key: string;
    status: string;
    user_id: number;
  };
  wristband_category_id: number;
  wristband_id: number;
  wristband_category: {
    description: string;
    id: number;
    image_path: string;
    name: string;
    retail_price: number;
  };
  shipping_address: {
    address_line_1: string;
    address_line_2: string;
    city_id: number;
    country_id: number;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    latitude: string;
    longitude: string;
    phone_number: string;
    postal_code: string;
    state_id: number;
    user_id: number;
  };
};
type userTicketsProps = {
  userDashboardData?: userDashboardTypes[];
};

const UserTickets: React.FC<userTicketsProps> = ({ userDashboardData }) => {
  return (
    <UserTicketsStyle>
      <PrimaryHeading className="mb-3">
        Wristbands will be mailed to you before the concert date!
      </PrimaryHeading>
      <div className="user__ticket__container">
        {userDashboardData?.map(
          ({
            id,
            status,
            wristband_id,
            shipping_address_id,
            wristband_category: { name },
            shipping_address: {
              first_name,
              last_name,
              email,
              phone_number,
              address_line_1,
            },
          }) => (
            <div className="user__ticket__tickets" key={id}>
              <div className="user__ticket__tickets-detail">
                <h4 className="user__ticket__tickets-name">{name}</h4>
                <div className="user__ticket__ticket-number d-flex align-items-center justify-content-between">
                  <h6 className="user__ticket__ticket-number-id">
                    Ticket id: #{id}
                  </h6>
                  <p className="user__ticket__ticket-wristband-id ">
                    Wristband id: #{wristband_id}
                  </p>
                </div>
                <p className="user__ticket__ticket-date ">
                  Friday, 1 July 2023, 4:00 PM – 2:00 AM EST
                </p>
              </div>
              <div className="user__ticket__person-info">
                <h4 className=" user__ticket__person-name">
                  {first_name} {last_name}
                </h4>
                {status === "readyforshipping" && (
                  <Link
                    to={`/user-shipping-addresses/update/${shipping_address_id}`}
                  >
                    <p className="user__ticket__person-info-edit">
                      <EditOutlined className="cursor-pointer" />
                    </p>
                  </Link>
                )}
                <p className="mb-1 user__ticket__person-email">{email}</p>
                <p className="mb-1 user__ticket__person-address">
                  {address_line_1}
                </p>
                <p
                  className="mb-1 user__ticket__person-status"
                  style={{
                    color:
                      status === "readyforshipping" ? OrangeColor : lightBlue3,
                  }}
                >
                  {status}
                </p>
              </div>
            </div>
          )
        )}
      </div>
      <div className="btn-container mt-4 mb-4">
        <ColoredButton type="button">
          <span>PRINT Receipt</span>
        </ColoredButton>
      </div>
    </UserTicketsStyle>
  );
};

export default UserTickets;
