import { Row, Col } from "react-bootstrap";
import { PrimaryHeading2 } from "../../../Components/GlobalStyle";
import plusicon from "../../../assets/litgalaxyicons/ic_tickets_qty_add.svg";
import ShippingAddressCard from "../../../Components/litgalaxys/ShippingAddressCard/ShippingAddressCard";
import login6image from "../../../assets/litgalaxyicons/main-img.jpg";
import { axiosRequest } from "../../../utils/axios.utils";
import { toast } from "react-toastify";
import { shipping_addresses_url } from "../../../utils/api_urls";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { useRef } from "react";
import Loader from "../../../Components/Loader/Loader";
import warningImg from "../../../assets/images/warning.png";
import { useNavigate } from "react-router-dom";
import NoDataFound from "./NoDataFound";
import { GetUserShippingAddresses } from "../../../hooks/RQHooks";

export type userShippingAddressTypes = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  address_line_1: string;
  address_line_2: string;
  country_id: number;
  state_id: number;
  city_id: number;
  postal_code: string;
  phone_number: string;
  user_id: number;
  latitude: string;
  longitude: string;
  created_at: string;
  updated_at: string;
  country: {
    id: number;
    name: string;
    iso_code: string;
    language: string;
    currency_code: string;
    dailing_code: string;
    created_at: null;
    updated_at: null;
  };
  city: {
    id: number;
    name: string;
    state_id: number;
    created_at: null;
    updated_at: null;
  };
  state: {
    id: number;
    name: string;
    country_id: number;
    created_at: null;
    updated_at: null;
  };
};

const UserShippingAddresses = () => {
  const userShippingAddressToastId = useRef<any>(null);
  const navigate = useNavigate();

  // user shipping addresses promise
  const getShippingAddressesHandler = async () => {
    userShippingAddressToastId.current = toast(
      "getting shipping addresses...",
      {
        type: "info",
      }
    );
    const { data: response } = await axiosRequest({
      url: shipping_addresses_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  const onSuccess = (data: any) => {
    console.log({ data });

    toast.update(userShippingAddressToastId.current, {
      render: "got addresses successfully",
      type: "success",
      autoClose: 1000,
    });
  };
  const onError = () => {
    toast.update(userShippingAddressToastId.current, {
      render: "something went wrong",
      type: "error",
      autoClose: 1000,
    });
  };

  // query shipping addresses
  const {
    data: userShippingAddressesData,
    isLoading,
    isFetching,
    isError,
  }: any = GetUserShippingAddresses(
    getShippingAddressesHandler,
    onSuccess,
    onError
  );

  if (isLoading) return <Loader />;
  if (isError)
    return (
      <article>
        <img src={warningImg} alt="error" />
        <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
      </article>
    );

  return (
    <>
      <Row className="flex-wrap-reverse">
        <Col md={8}>
          <div className="d-flex justify-content-between align-items-center">
            <PrimaryHeading2>Shipping Address</PrimaryHeading2>
            <figure>
              <img
                src={plusicon}
                alt="plusicon"
                className="cursor-pointer"
                onClick={() => navigate("/create-shipping-address")}
              />
            </figure>
          </div>
          {userShippingAddressesData.length === 0 ? (
            <NoDataFound />
          ) : (
            userShippingAddressesData.map(
              (data: userShippingAddressTypes, index: number) => (
                <ShippingAddressCard
                  shippingAddressesLength={userShippingAddressesData.length}
                  key={index}
                  data={data}
                />
              )
            )
          )}
        </Col>
        <Col md={4} className="my-2 mx-auto d-flex justify-content-center">
          <figure className="my-3 mb-4">
            <img src={login6image} alt="login-8" />
          </figure>
        </Col>
      </Row>
    </>
  );
};

export default UserShippingAddresses;
