import { Container } from "react-bootstrap";
import Headbar from "../../Components/litgalaxys/Headbar/Headbar";
import HomepageStyle from "./style";

const Homepage = ({ children }: any) => {
  return (
    <HomepageStyle>
      <Headbar />
      <Container className="homepage__child">{children}</Container>
    </HomepageStyle>
  );
};

export default Homepage;
