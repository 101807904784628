import styled from "styled-components";
import {
  maxWidthDevice,
  PrimaryColor,
  tertiaryGrey13,
} from "../../GlobalStyle";

const TicketCatagoriesStyle = styled.div`
  position: sticky;
  width: 100%;
  top: 70px;

  @media ${maxWidthDevice.tablet} {
    position: static;
  }
  .tickets__card-main {
    border: 0.5px solid ${tertiaryGrey13};
    padding: 0 10px;
    .tickets__card {
      border-radius: 2px;
      padding: 10px;
      cursor: pointer;
      @media (max-width: 992px) {
        text-align: center;
        height: auto;
      }
    }
    .tickets__admission {
      @media (max-width: 992px) {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: 0 auto;
      }
    }
    .tickets__heading {
      font-family: "EnnVisionsMedium";
      font-size: 16px;
      letter-spacing: 0.1px;
      color: #1b283f;
      @media (max-width: 769px) {
        text-align: center;
      }
    }
    .tickets__subheading {
      font-size: 13px;
      font-family: "EnnVisions";
      letter-spacing: 0px;
      color: #464e5f;
      opacity: 1;
    }
    .tickets__added {
      img {
        width: 32px;
        height: 32px;
      }
      p {
        font-size: 27px;
        font-family: "EnnVisionsMedium";
        letter-spacing: 0.16px;
        color: #1b283f;
      }
    }
    .price {
      font-family: "EnnVisionsMedium";
      font-size: 20px;
    }
  }
  .tickets__buy-btn {
    width: 100%;
    background: ${PrimaryColor};
    border-radius: 5px;
    height: 45px;
    span {
      font-family: "EnnVisionsbold";
      font-size: 18px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
`;
export default TicketCatagoriesStyle;
