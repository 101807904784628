import { Col, Row } from "react-bootstrap";

import Style from "./style";
import { PrimaryHeading } from "./../../GlobalStyle";
const Whenandwhere = () => {
  const WhenandwhereData = [
    {
      title: "First Day",
      sub_title: "Friday, 1 July 2023,",
      tertiary_Heading: "4:00 PM – 2:00 AM EST",
    },
    {
      title: "LitGalaxy,",
      sub_title: "556210 Mulmur Melancthon Townline,",
      tertiary_Heading: "Melancthon, ON Canada",
    },
    {
      title: "Secondary Day",
      sub_title: "Friday, 2 July 2023,",
      tertiary_Heading: "4:00 PM – 2:00 AM EST",
    },
    {
      title: "LitGalaxy",
      sub_title: "556210 Mulmur Melancthon Townline,",
      tertiary_Heading: "Melancthon, ON Canada",
    },
    {
      title: "Third Day",
      sub_title: "Friday, 3 July 2023,",
      tertiary_Heading: "4:00 PM – 2:00 AM EST",
    },
    {
      title: "LitGalaxy",
      sub_title: "556210 Mulmur Melancthon Townline,",
      tertiary_Heading: "Melancthon, ON Canada",
    },
  ];
  return (
    <Style>
      <PrimaryHeading>When And Where</PrimaryHeading>
      <Row className="when-and-where-box">
        {WhenandwhereData.map(
          ({ title, sub_title, tertiary_Heading }, index) => {
            return (
              <Col md={6} key={index} className="when-and-where-box-card pb-2">
                <div className="d-flex">
                  <div>
                    <h5 className="when__heading">{title}</h5>
                    <p className="when__subheading">
                      <span>{sub_title}</span>
                      <span>{tertiary_Heading}</span>
                    </p>
                  </div>
                  {index % 2 == 0 && <div className="right-border ms-auto" />}
                </div>
              </Col>
            );
          }
        )}
      </Row>
    </Style>
  );
};

export default Whenandwhere;
