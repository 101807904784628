import styled from "styled-components";
import { lightGrey, whiteColor } from "../../../Components/GlobalStyle";

const CreateShippingAddressStyle = styled.div`
  .inner-container {
    padding: 12px;
    background: ${whiteColor};
    border: 0.5px solid ${lightGrey};
  }
`;

export default CreateShippingAddressStyle;
