import { HeartOutlined } from "@ant-design/icons";
import { PrimaryHeading } from "../../GlobalStyle";
import LiveUpdateStyle from "./style";
import mainImg from "../../../assets/images/main-img.png";
import bgForest from "../../../assets/images/BG_Forest_04.jpg";

const LiveUpdate = () => {
  return (
    <LiveUpdateStyle className="mb-3">
      <PrimaryHeading className="mt-4 mb-3 ms-4">
        Events on July 01
      </PrimaryHeading>
      {[mainImg, bgForest].map((img, index) => (
        <div className="liveUpdate__container" key={index}>
          <figure className="liveUpdate__image-container mb-0">
            <img src={img} alt={img} className="liveUpdate__image" />
          </figure>
          <div className="liveUpdate-content p-3">
            <div className="d-flex align-items-center">
              <HeartOutlined />
              <p className="likes mb-0 ms-2">3.9K</p>
            </div>
            <h5 className="liveUpdate__heading">Natural History Museum</h5>
            <p className="mb-2 liveUpdate__subheading">
              The Natural History Museum in London is a museum that exhibits a
              vast range of specimens from various segments of natural history.
            </p>
            {/* <p className="mb-0 text-end liveUpdate__date">Sat, 18 March 2023</p> */}
          </div>
        </div>
      ))}
    </LiveUpdateStyle>
  );
};

export default LiveUpdate;
