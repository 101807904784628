import React, { useContext, useState } from "react";
import { Drawer } from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SideMenuStyle from "./style";
import { ContextApiData } from "../../utils/CreateContext";
import CustomButton from "../CustomButton/CustomButton";
import { PrimaryColor } from "../GlobalStyle";
import { LoggedInHandler, LogOutHandler } from "../../ProtectedRoute";

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const { isShowSideMenu, setIsShowSideMenu } = useContext(ContextApiData);
  const { setIsLogin } = useContext(ContextApiData);

  const onClose = () => {
    setIsShowSideMenu(false);
  };

  const sideBarMenuItems = [
    { title: "Home", link: "/" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Shipping Addresses", link: "/user-shipping-addresses" },
    { title: "Cart", link: "/user-cart" },
    { title: "Order Summary", link: "/order-summary" },
    { title: "Add Credit Card", link: "/create-credit-card" },
    { title: "User Credit Cards", link: "/credit-cards" },
  ];
  return (
    <Drawer
      placement="left"
      onClose={onClose}
      open={isShowSideMenu}
      width={250}
    >
      <SideMenuStyle>
        <Link to="/" className="text-decoration-none">
          <h6 className="title cursor-pointer">Lit Galaxy Festival</h6>
        </Link>
        <div className="menu-links">
          {sideBarMenuItems.map(({ title, link }, index) => (
            <p key={index} className="menu-links__item mb-0 mt-3">
              <NavLink onClick={onClose} to={link}>
                {title}
              </NavLink>
            </p>
          ))}
        </div>
        <div className="d-sm-none mt-2">
          <CustomButton
            bgcolor={PrimaryColor}
            color="white"
            padding="6px"
            width="120px"
            type="submit"
            title="Logout"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              LogOutHandler();
              setIsLogin(null);
              navigate("/login");
            }}
          />
        </div>
      </SideMenuStyle>
    </Drawer>
  );
};

export default SideMenu;
