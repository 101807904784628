import { Col, Row } from "react-bootstrap";
import { tertiaryGrey8 } from "../../Components/GlobalStyle";
import Aboutevent from "../../Components/litgalaxys/aboutevent/Aboutevent";
import Hero from "../../Components/litgalaxys/hero/Hero";
import Tickets from "../../Components/litgalaxys/TicketCatagories/TicketCatagories";
import Whenandwhere from "../../Components/litgalaxys/whenandwhere/Whenandwhere";
import Wristand from "../../Components/litgalaxys/wristand/Wristand";

const Home = () => {
  return (
    <div style={{ backgroundColor: tertiaryGrey8 }}>
      <Row className="flex-wrap-reverse">
        <Col md={6}>
          <Hero />
          <Whenandwhere />
          <Aboutevent />
          {/* <Foodservices /> */}
          <Wristand />
          {/* <Liveupdateevent /> */}
        </Col>
        <Col md={6} className="mb-3 tickets-container">
          <Tickets />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
