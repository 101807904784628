import CardStyle from "./style";
import plusicon from "../../assets/litgalaxyicons/ic_tickets_remove.svg";
import visaicon from "../../assets/litgalaxyicons/ic_pro_visa.svg";
import mastericon from "../../assets/litgalaxyicons/ic_pro_master.svg";
import { Primarypara } from "../GlobalStyle";

type cardProps = {
  brand: string;
  holder_name: string;
  cartNumber: string;
};

const Card = ({ brand, holder_name, cartNumber }: cardProps) => {
  const creditCardsIcons: any = {
    visa: visaicon,
    master: mastericon,
  };
  return (
    <CardStyle>
      <div className="d-flex align-items-center my-2" style={{ gap: "10px" }}>
        <figure>
          <img src={creditCardsIcons[brand]} alt={`image of ${brand}`} />
        </figure>
        <div>
          <h6 className="card-title">{holder_name}</h6>
          <Primarypara className="card-number">{cartNumber}</Primarypara>
        </div>
      </div>
    </CardStyle>
  );
};

export default Card;
