import styled from "styled-components";
import {
  darkbluelight,
  darkbluelight2,
  lightGrey2,
  lightGrey7,
  pureDark,
  secondaryDark3,
  tertiaryGrey21,
  whiteColor,
} from "../../GlobalStyle";
import { maxWidthDevice } from "./../../GlobalStyle";

const TicketInfoStyle = styled.section`
  background: ${whiteColor};
  border: 1px solid ${lightGrey7};
  box-shadow: 0 5rem 2rem rgba(255, 255, 255, 0.7);
  padding: 10px;
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-start;
  gap: 40px;
  @media ${maxWidthDevice.tablet} {
    flex-direction: column;
    align-items: center;
  }
  .ticket__purchase {
    /* border: 1px solid red; */
    flex: 1;
    .ticket__date {
      font-family: "EnnVisions";
      font-size: 14px;
      span:nth-child(1) {
        color: ${darkbluelight2};
      }
      span:nth-child(2) {
        color: ${lightGrey2};
      }
    }
    .ticket__id-num {
      font-family: "EnnVisions";
      font-size: 14px;
      color: ${secondaryDark3};
    }
    .ticket__price {
      font-family: "EnnVisionsMedium";
      font-size: 20px;
      color: ${secondaryDark3};
    }
  }

  .ticket__ownership {
    flex: 1;
    /* border: 1px solid red; */
  }
  .ticket-owner-phone-number {
    font-family: "EnnVisionsMedium";
    font-size: 16px;
    color: ${pureDark};
  }
  .ticket__owner-email,
  .ticket__owner-address {
    font-family: "EnnVisions";
    font-size: 16px;
    color: ${tertiaryGrey21};
  }
  .ticket__category,
  .ticket__owner-name {
    font-family: "EnnVisionsMedium";
    font-size: 16px;
    color: ${darkbluelight};
  }
`;
export default TicketInfoStyle;
