import Style from "./style";
import profile from "../../../assets/icons/ic_profile_placeholder.svg";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MenuOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { LoggedInHandler, LogOutHandler } from "../../../ProtectedRoute";
import CustomButton from "../../CustomButton/CustomButton";
import { lightGrey, PrimaryColor } from "../../GlobalStyle";
import { useContext, useRef, useState } from "react";
import { ContextApiData } from "../../../utils/CreateContext";
import SideMenu from "../../SideMenu/SideMenu";
import { axiosRequest } from "../../../utils/axios.utils";
import { toast } from "react-toastify";
import {
  authorizationHeader,
  Base_Url,
  extend_time_url,
  remaining_time_url,
} from "../../../utils/api_urls";
import { useQuery, useQueryClient } from "react-query";
import CustomModal from "../../Modal/CustomModal";
import DeleteConfirmation from "../../DeleteConfirmation/DeleteConfirmation";
import axios from "axios";

const Headbar = () => {
  const navigate = useNavigate();
  const timerToastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const { cartTimer, setCartTimer } = useContext(ContextApiData);
  const [isShowModal, setIsShowModal] = useState(false);
  const { isLogin, setIsLogin } = useContext(ContextApiData);

  // user cart timer promise
  const userTimerPromise = async () => {
    const { data: response }: any = await axiosRequest({
      url: remaining_time_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };
  const onError = () => {
    toast.update(timerToastId.current, {
      render: "something went wrong",
      type: "error",
      autoClose: 1000,
    });
  };

  // on Success of data handler

  const onSuccess = ({ time, seconds }: any) => {
    console.log({ time });
    if (seconds === 60) {
      setIsShowModal(true);
    }
    if (seconds === 0) {
      setCartTimer(false);
      setIsShowModal(false);

      queryClient.invalidateQueries("user-cart-items");
      return;
    }
  };

  // user cart timer query
  const {
    data: userCartTime,
    isLoading,
    isFetching,
    isError,
  }: any = useQuery("cart-timer", userTimerPromise, {
    onError,
    refetchInterval: 1000,
    enabled: cartTimer,
    select: (time) => {
      const totalSeconds = time;

      // 👇️ get the number of full minutes
      const minutes = Math.floor(totalSeconds / 60);
      // 👇️ get the remainder of the seconds
      const seconds = totalSeconds % 60;

      function padTo2Digits(num: any) {
        return num.toString().padStart(2, "0");
      }

      // ✅ format as MM:SS
      const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
      return { time: result, seconds: time };
    },
    refetchIntervalInBackground: true,
    onSuccess,
  });

  console.log({ userCartTime });
  const navigateToDashboard = () => {
    navigate("/");
  };
  const { setIsShowSideMenu, cartSummary } = useContext(ContextApiData);
  // timer extend handler
  const extendTimeHandler = () => {
    axios.get(Base_Url + extend_time_url, {
      headers: {
        ...authorizationHeader,
      },
    });
    setCartTimer(true);
  };
  return (
    <Style>
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
      >
        <DeleteConfirmation
          setIsShowModal={setIsShowModal}
          title="Do you want delete address?"
          handleConfirmation={extendTimeHandler}
        />
      </CustomModal>
      <SideMenu />
      <Container className="header">
        <div className="d-flex align-items-center">
          {isLogin && (
            <MenuOutlined
              onClick={() => setIsShowSideMenu((prevValue) => !prevValue)}
              className="me-3 cursor-pointer"
            />
          )}
          <h4
            className="mb-0 header__heading cursor-pointer"
            onClick={navigateToDashboard}
          >
            Lit Galaxy Festival
          </h4>
        </div>
        {isLogin && isLogin.mobileVerified ? (
          <div className="header__profile-container">
            <div className="d-flex align-items-center">
              <div className="nav-container">
                <ShoppingCartOutlined
                  onClick={() => navigate("/user-cart")}
                  style={{
                    fontSize: "32px",
                  }}
                  className="me-4 cursor-pointer"
                />
                <div className="amount-container">
                  <p className="total-amount">
                    {cartSummary ? cartSummary.length : 0}
                  </p>
                </div>
              </div>
              {/* shows when usercart have items */}
              {userCartTime && (
                <h6 className="mb-0 header__secondaryheading">
                  TIME LEFT {userCartTime ? userCartTime.time : "00:00"}
                </h6>
              )}
            </div>
            <p className="mb-0 header__profile-name cursor-pointer">
              Adnan Qureshi
            </p>
            <figure className="header__profile-image-container m-0 cursor-pointer d-none d-sm-block">
              <img
                src={profile}
                alt="image of profile"
                className="header__profile--image"
              />
            </figure>
            <div className="d-none d-sm-block">
              <CustomButton
                bgcolor={PrimaryColor}
                color="white"
                padding="6px"
                width="80px"
                type="submit"
                title="Logout"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() => {
                  LogOutHandler();
                  setIsLogin(null);
                  navigate("/login");
                }}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <div className="me-2">
              <CustomButton
                bgcolor={PrimaryColor}
                color="white"
                padding="6px"
                width="80px"
                type="submit"
                title="Login"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() => {
                  navigate("/login");
                }}
              />
            </div>
            <CustomButton
              bgcolor={lightGrey}
              color={PrimaryColor}
              padding="6px"
              width="80px"
              type="submit"
              title="Signup"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              border={`1px solid ${lightGrey}`}
              clicked={() => {
                navigate("/register");
              }}
            />
          </div>
        )}
      </Container>
    </Style>
  );
};

export default Headbar;
