import styled from "styled-components";
import {
  lightBlue3,
  lightdarkblue,
  PrimaryColor,
  secondaryDark3,
  tertiaryGrewish2,
  tertiaryGrey7,
  whiteColor,
} from "../../GlobalStyle";

const HeaderBarStyle = styled.header`
  background-color: ${whiteColor};
  box-shadow: 0px 0px 3px ${lightdarkblue};
  padding: 5px;
  position: fixed;
  width: 100%;
  margin-top: 0;
  @media (max-width: 515px) {
    height: 70px;
  }
  .nav-container {
    display: block;
    position: relative;
  }

  .amount-container {
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background: ${lightBlue3};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-amount {
    color: white;
    margin-bottom: 0;
    font-size: 12px;
    font-family: "EnnVisionsMedium";
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    &__heading {
      font-family: "EnnVisionsMedium";
      font-size: 21px;
      color: ${PrimaryColor};
    }
    &__secondaryheading {
      font-family: "EnnVisionsMedium";
      font-size: 18px;
      color: ${secondaryDark3};
      text-transform: uppercase;
    }
    &__profile-name {
      font-family: EnnVisions;
      font-size: 14px;
      color: ${tertiaryGrey7};
    }
    @media screen and (max-width: 768px) {
      max-width: 750px !important;
      width: 100% !important;
      justify-content: center;
      flex-wrap: wrap;
      gap: 6px;
    }
    &__profile-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      @media screen and (max-width: 768px) {
        gap: 16px;
        justify-content: center;
        flex-wrap: wrap;
      }
      figure {
        width: 45px;
        height: 45px;
      }
      .header__profile--image {
        width: 100%;
        height: 100%;
        border: 1px dashed ${tertiaryGrewish2};
        border-radius: 29px;
        display: inline-block;
      }
    }
  }
`;
export default HeaderBarStyle;
