import { Suspense, useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";

import { Routes, Route, Link } from "react-router-dom";
import { ContextApiData } from "./utils/CreateContext";
import scroll_up from "./assets/icons/ic_scroll_to_top .svg";
import useScrollToTop from "./hooks/useScrollToTop";
import Homepage from "./Screens/Homepage/Homepage";
import Register from "./Screens/Register/Register";
import RegisterPhone from "./Components/RegisterPhone/RegisterPhone";
import VerifyOtp from "./Screens/VerifyOtp/VerifyOtp";
import Login from "./Screens/Login/Login";
import EditPhone from "./Screens/EditPhone/EditPhone";
import Home from "./Screens/litgalaxy/Home";
import Tickets from "./Screens/litgalaxy/tickets/Tickets";
import Address from "./Screens/litgalaxy/tickets/UserDetails";
import CreateCreditCard from "./Screens/litgalaxy/tickets/CreateCreditCard";
// import ShippingDetails from "./Screens/litgalaxy/tickets/ShippingDetails";
import Error404Page from "./Components/404Page/404Page";
import ProtectedRoute from "./ProtectedRoute";
import UserDashboard from "./Screens/UserDashboard/UserDashboard";
import UserCart from "./Screens/litgalaxy/UserCart/UserCart";
import OrderSummary from "./Screens/litgalaxy/OrderSummary/OrderSummary";
import UserShippingAddresses from "./Screens/litgalaxy/UserShippingAddresses/UserShippingAddresses";
import CreateShippingAddress from "./Screens/litgalaxy/CreateShippingAddress/CreateShippingAddress";
import ShippingAddressDetails from "./Screens/litgalaxy/ShippingAddressDetails/ShippingAddressDetails";
import UpdateShippingAddress from "./Screens/litgalaxy/UpdateShippingAddress/UpdateShippingAddress";
import CreditCards from "./Screens/CreditCards/CreditCards";
import TermsAndConditions from "./Screens/litgalaxy/TermsAndConditions/TermsAndConditions";
import ResetEmail from "./Screens/ResetPasswordByEmail/ResetEmail";
import VerifyEmail from "./Screens/ResetPasswordByEmail/VerifyCodeByEmail";
// import lazyLoad from "./LazyLoad";
// import Login10 from './Screens/litgalaxy/login-10/Login10';
// const Error = lazyLoad("./Components/404Page/404Page");

// const EditPhone = lazyLoad("./Screens/CreateProfessional/EditPhone");

// const Register = lazyLoad("./Components/Register/Index");
// const RegisterPhone = lazyLoad("./Components/RegisterPhone/Index");
// const VerifyOtp = lazyLoad("./Screens/VerifyOtp/Index");
// const Login = lazyLoad("./Screens/Login/Login");

function App() {
  const { changeNavColor, scrollToTop } = useContext(ContextApiData);
  useScrollToTop();
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // };

  // useEffect(() => {
  //   clearCacheData();
  //   // 👇️ scroll to top on page load
  //   // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);

  return (
    <Suspense fallback={<h6>Loading...</h6>}>
      <ToastContainer />
      <span className={`arrow-box ${changeNavColor ? "d-block" : "d-none"}`}>
        <img onClick={scrollToTop} src={scroll_up} alt="scroll-up" />
      </span>
      <Routes>
        <Route
          path="login"
          element={
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="register"
          element={
            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          }
        />
        <Route
          path="register-phone"
          element={
            <ProtectedRoute>
              <RegisterPhone />
            </ProtectedRoute>
          }
        />
        <Route
          path="verify-otp"
          element={
            <ProtectedRoute>
              <VerifyOtp />
            </ProtectedRoute>
          }
        />
        <Route
          path="reset-email"
          element={
            <ProtectedRoute>
              <ResetEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="verify-email-code"
          element={
            <ProtectedRoute>
              <VerifyEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="verify-reset-email"
          element={
            <ProtectedRoute>
              <EditPhone />
            </ProtectedRoute>
          }
        />
        <Route
          path="terms"
          element={
            <ProtectedRoute>
              <TermsAndConditions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Homepage>
                <Home />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserDashboard />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="tickets"
          element={
            <ProtectedRoute>
              <Homepage>
                <Tickets />
              </Homepage>
            </ProtectedRoute>
          }
        />

        <Route
          path="user-details"
          element={
            <ProtectedRoute>
              <Homepage>
                <Address />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-credit-card"
          element={
            <ProtectedRoute>
              <Homepage>
                <CreateCreditCard />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="credit-cards"
          element={
            <ProtectedRoute>
              <Homepage>
                <CreditCards />
              </Homepage>
            </ProtectedRoute>
          }
        />

        <Route
          path="create-shipping-address"
          element={
            <ProtectedRoute>
              <Homepage>
                <CreateShippingAddress />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="user-cart"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserCart />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="user-cart/:singleCartId"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserCart />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="user-shipping-addresses"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserShippingAddresses />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="user-shipping-addresses/:addressId"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserShippingAddresses />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="user-shipping-addresses/update/:cartItemId"
          element={
            <ProtectedRoute>
              <Homepage>
                <UserShippingAddresses />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="shipping-address-details/:addressId"
          element={
            <ProtectedRoute>
              <Homepage>
                <ShippingAddressDetails />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="update-shipping-address/:addressId"
          element={
            <ProtectedRoute>
              <Homepage>
                <UpdateShippingAddress />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route
          path="order-summary"
          element={
            <ProtectedRoute>
              <Homepage>
                <OrderSummary />
              </Homepage>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </Suspense>
  );
}

export default App;
