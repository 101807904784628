import { PrimaryHeading } from "../../GlobalStyle";
import OrderSummaryStyle from "./style";

const OrderSummary = () => {
  return (
    <OrderSummaryStyle>
      <PrimaryHeading>Order Summary</PrimaryHeading>
      <div className="summary__container">
        <div>
          <p>3 X General Admission </p>
          <p> 2 X VIP (EARLY BIRD TICKETS)</p>
          <p className="mb-0">Subtotal</p>
          <p>Fees ©</p>
          <p className="summary__subheading">Total</p>
        </div>
        <div>
          <p>$449 CAD</p>
          <p> $799 CAD</p>
          <p className="mb-0">$1799 CAD</p>
          <p>$19 CAD</p>
          <p className="summary__subheading">$2799 CAD</p>
        </div>
      </div>
    </OrderSummaryStyle>
  );
};

export default OrderSummary;
