import styled from "styled-components";
import { maxWidthDevice } from "../../Components/GlobalStyle";

const HomepageStyle = styled.div`
  .homepage__child {
    padding-top: 72px;
    @media ${maxWidthDevice.tablet} {
      padding-top: 86px;
    }
    @media ${maxWidthDevice.mobilexL} {
      padding-top: 100px;
    }
    @media ${maxWidthDevice.mobileS} {
      padding-top: 120px;
    }
  }
`;

export default HomepageStyle;
