import styled from "styled-components";

const LoaderStyle = styled.div`
  display: flex;
  align-items: center;
  height: 20vh;
  justify-content: center;
  width: 40vh;
`;

export default LoaderStyle;
