import minus from "../../../assets/litgalaxyicons/ic_tickets_add.svg";
import plus from "../../../assets/litgalaxyicons/ic_tickets_remove.svg";
import TicketCatagoriesStyle from "./style";
import { useContext, useRef, useState } from "react";
import { PrimaryColor, tertiaryGrey13 } from "../../GlobalStyle";
import { useNavigate } from "react-router-dom";
import { ContextApiData } from "../../../utils/CreateContext";
import { toast } from "react-toastify";
import {
  Base_Url,
  create_cart,
  shipping_addresses_url,
  tickets_data_url,
} from "../../../utils/api_urls";
import { useMutation, useQuery } from "react-query";
import Loader from "../../Loader/Loader";
import { axiosRequest } from "../../../utils/axios.utils";
import warningImg from "../../../assets/images/warning.png";
import { LoggedInHandler } from "../../../ProtectedRoute";
import axios from "axios";
import { GetUserShippingAddresses } from "../../../hooks/RQHooks";

type ticketType = {
  id: string;
  name: string;
  price: number;
  currency: string;
  description: string;
  image: string;
  available_tickets: number;
};

const Tickets = () => {
  const navigate = useNavigate();
  const [allTicketsData, setAllTicketsData] = useState<any>([]);
  const { setUserTickets, setShippingAddressLength } =
    useContext(ContextApiData);
  const toastId = useRef<any>(null);
  const cartToastId = useRef<any>(null);
  const userShippingAddressToastId = useRef<any>(null);

  // get ticket categories promise
  const getTicketsHandler = async () => {
    const { data: response } = await axiosRequest({
      url: tickets_data_url,
    });
    return response.data;
  };

  // get tickets categories query
  const { isLoading, isError, isFetching } = useQuery(
    "ticket-categories",
    getTicketsHandler,
    {
      onSuccess: (data) => {
        setAllTicketsData(
          data.map((ticketItem: ticketType) => ({
            ...ticketItem,
            available_tickets: 0,
          }))
        );
        toastId.current = toast("got tickets successfully.", {
          type: "success",
          autoClose: 1000,
        });
      },
      refetchOnWindowFocus: false,
      onError: () => {
        toastId.current = toast("something went wrong.", {
          type: "error",
          autoClose: false,
        });
      },
    }
  );
  // ticket catagories toggler
  const ticketCardsToggle = (index: number) => {
    const data = [...allTicketsData];
    const selectedIndex = data.findIndex(({ selected }) => selected === true);
    if (selectedIndex === index) {
      return;
    }
  };

  // ticket catagories counter
  const ticketsCountHandler = (index: number, action: string) => {
    const data = [...allTicketsData];
    if (action === "add") {
      data[index].available_tickets += 1;
      setAllTicketsData(data);
    } else {
      if (data[index].available_tickets > 0) {
        data[index].available_tickets -= 1;
        setAllTicketsData(data);
      }
    }
  };

  // create cart promise
  const createCartHandler = async (data: any) => {
    const { data: response } = await axios.post(Base_Url + create_cart, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // create cart mutation
  const { mutate } = useMutation(createCartHandler, {
    onSuccess: () => {
      cartToastId.current = toast("cart added successfully", {
        type: "success",
        autoClose: 1000,
      });
      navigate("/user-cart");
    },
    onError: ({ response: { data } }: any) => {
      if (data.message) {
        cartToastId.current = toast(data.message, {
          type: "error",
          autoClose: false,
        });
        return;
      }
      cartToastId.current = toast("something went wrong", {
        type: "error",
        autoClose: false,
      });
    },
  });

  // user shipping addresses promise
  const getShippingAddressesHandler = async () => {
    const { data: response } = await axiosRequest({
      url: shipping_addresses_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  const onSuccess = () => {
    userShippingAddressToastId.current = toast("got addresses successfully", {
      type: "success",
      autoClose: 1000,
    });
  };
  const onError = () => {
    userShippingAddressToastId.current = toast("something went wrong", {
      type: "success",
      autoClose: false,
    });
  };

  // query shipping addresses
  const {
    data: userShippingAddressesData,
    isLoading: shippingAddressLoading,
    isFetching: shippingAddressIsFetching,
    isError: shippingAddressIsError,
  }: any = GetUserShippingAddresses(
    getShippingAddressesHandler,
    onSuccess,
    onError,
    !LoggedInHandler() ? false : true
  );

  //create cart handler
  const handleCreateCart = () => {
    // get selected tickets by user
    const selectedTickets = allTicketsData.reduce(
      (total: number, ticket: ticketType) =>
        (total += ticket.available_tickets),
      0
    );
    // select at least one ticket
    if (selectedTickets < 1) {
      toastId.current = toast("please select at least one ticket", {
        type: "warning",
      });
      return;
    }
    // check if user is logged in or not
    if (LoggedInHandler()) {
      if (userShippingAddressesData.length === 0 && !isLoading) {
        toast("Please add shipping address first...", {
          type: "warning",
        });
        setUserTickets({
          cartItems: allTicketsData.map(
            ({ id, available_tickets }: ticketType) => ({
              wristbandCategoryId: id,
              quantity: available_tickets,
            })
          ),
        });
        setShippingAddressLength(userShippingAddressesData.length);
        navigate("/create-shipping-address");
      } else {
        mutate({
          cartItems: allTicketsData.map(
            ({ id, available_tickets }: ticketType) => ({
              wristbandCategoryId: id,
              quantity: available_tickets,
            })
          ),
        });
      }
    } else {
      // save tickets in context api even user is logged in or not
      setUserTickets({
        cartItems: allTicketsData.map(
          ({ id, available_tickets }: ticketType) => ({
            wristbandCategoryId: id,
            quantity: available_tickets,
          })
        ),
      });
      navigate("/login");
    }
  };
  return (
    <TicketCatagoriesStyle>
      {isLoading || isFetching ? (
        <Loader />
      ) : isError ? (
        <article>
          <img src={warningImg} alt="error" />
          <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
        </article>
      ) : (
        <div className="tickets__card-main">
          {allTicketsData.map(
            (
              {
                price,
                available_tickets,
                currency,
                description,
                id,
                // image,
                name,
              }: ticketType,
              index: number
            ) => {
              return (
                <div
                  key={id}
                  className="d-flex flex-column tickets__card my-2"
                  onClick={() => ticketCardsToggle(index)}
                  style={{
                    border: `1px solid ${true ? PrimaryColor : tertiaryGrey13}`,
                  }}
                >
                  <h6 className="tickets__heading mb-0">{name}</h6>
                  <div
                    className="d-flex justify-content-md-between 
                  justify-content-center
                  align-items-center tickets__admission"
                  >
                    <h5 className="tickets__subheading mb-0">{description}</h5>
                    <div className="d-flex align-items-center tickets__added">
                      <img
                        src={minus}
                        alt="minus-icon"
                        onClick={() => ticketsCountHandler(index, "minus")}
                      />
                      <p className="mb-0 mx-3 fs-5">{available_tickets}</p>
                      <img
                        src={plus}
                        alt="plus-icon"
                        onClick={() => ticketsCountHandler(index, "add")}
                      />
                    </div>
                  </div>
                  <p className="text-center mb-2 price">
                    ${price} {currency}
                  </p>
                </div>
              );
            }
          )}
          <button className="tickets__buy-btn mt-2" onClick={handleCreateCart}>
            <span>BUY TICKET</span>
          </button>
        </div>
      )}
    </TicketCatagoriesStyle>
  );
};

export default Tickets;
