import {
  PrimaryHeading2,
  ColoredButton,
  Primarypara,
} from "./../../GlobalStyle";
import plusicon from "../../../assets/litgalaxyicons/ic_tickets_remove.svg";
import visaicon from "../../../assets/litgalaxyicons/ic_pro_visa.svg";
import mastericon from "../../../assets/litgalaxyicons/ic_pro_master.svg";
import { useNavigate } from "react-router-dom";
import { userCreditCardTypes } from "../../../Screens/litgalaxy/OrderSummary/OrderSummary";
import Card from "../../CreditCard/CreditCard";
import { cardNumber } from "card-validator/dist/card-number";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { LoggedInHandler } from "../../../ProtectedRoute";
import axios from "axios";
import { Base_Url, charge_on_card } from "../../../utils/api_urls";
import { useMutation, useQueryClient } from "react-query";
import checkIcon from "../.././../assets/icons/ic_check.svg";
import unCheckIcon from "../.././../assets/icons/ic_uncheck.svg";

type creditCardProps = {
  data?: userCreditCardTypes[];
  cartSummary: any;
};

const CreditCardInfo = ({ data, cartSummary }: creditCardProps) => {
  const navigate = useNavigate();
  const chargeOnCardToastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const [cardsData, setCardsData] = useState<any>(
    data?.map((cardData) => {
      console.log(cardData.is_default, "default value");
      if (cardData.is_default === 1) {
        return {
          ...cardData,
          selected: true,
        };
      }
      return {
        ...cardData,
        selected: false,
      };
    })
  );

  // charge on card promise
  const chargeOnCardHanlder = async (data: any) => {
    chargeOnCardToastId.current = toast("charge on card...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + charge_on_card,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  const handleCardToggle = (index: number) => {
    const data = [...cardsData];
    const updatedData = data
      .map((cardData) => ({
        ...cardData,
        selected: false,
      }))
      .map((cardData) => {
        if (cardData.id === index) {
          return { ...cardData, selected: true };
        }
        return cardData;
      });
    setCardsData(updatedData);
  };

  // charge on card mutation
  const { mutateAsync: ChargeOnCardMutation } = useMutation(
    chargeOnCardHanlder,
    {
      onSuccess: () => {
        toast.update(chargeOnCardToastId.current, {
          render: "charge on card successfully",
          type: "success",
          autoClose: 1000,
        });
        navigate("/dashboard");
      },
      onError: ({ response: { data } }: any) => {
        toast.update(chargeOnCardToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
    }
  );

  return (
    <div className="my-3">
      <div className="d-flex justify-content-between align-items-center">
        <PrimaryHeading2>Credit Cards Info</PrimaryHeading2>
        <figure className="mb-">
          <img
            onClick={() => navigate("/create-credit-card")}
            src={plusicon}
            className="cursor-pointer"
            alt="add-btn"
          />
        </figure>
      </div>
      <div className="card-info-container d-flex flex-column">
        {cardsData?.length === 0 ? (
          <PrimaryHeading2 className="text-center my-3">
            Please add credit card
          </PrimaryHeading2>
        ) : (
          <>
            {cardsData?.map(
              ({ id, brand, cartNumber, holder_name, selected }: any) => {
                return (
                  <div
                    key={id}
                    className="d-flex justify-content-between align-items-center card-info"
                  >
                    <Card
                      brand={brand}
                      cartNumber={cartNumber}
                      holder_name={holder_name}
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleCardToggle(id)}
                    >
                      <img
                        style={{ height: "26px" }}
                        src={selected ? checkIcon : unCheckIcon}
                        alt={selected ? "check-icon" : "uncheck icon"}
                      />
                    </span>
                  </div>
                );
              }
            )}
          </>
        )}
        <ColoredButton
          style={{ width: "100%" }}
          onClick={async () => {
            if (cartSummary && cartSummary.length < 1) {
              toast("No order summary available...", {
                type: "warning",
                autoClose: 1000,
              });
              return;
            }
            if (cardsData.length < 1) {
              toast("please add credit card...", {
                type: "warning",
                autoClose: 1000,
              });
              return;
            }
            await ChargeOnCardMutation({
              bank_card_id: cardsData.find(
                ({ selected }: any) => selected === true
              ).id,
            });
          }}
        >
          <span>CHECK OUT</span>
        </ColoredButton>
        {}
      </div>
    </div>
  );
};

export default CreditCardInfo;
