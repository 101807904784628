import { createContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { LoggedInHandler, LoginUserHandler } from "../ProtectedRoute";
import { orderSummaryTypes } from "../Screens/litgalaxy/OrderSummary/OrderSummary";
import { cart_item_types } from "../Screens/litgalaxy/UserCart/UserCart";
import { order_summary_url } from "./api_urls";
import { axiosRequest } from "./axios.utils";

type userTicketsType = {
  title: string;
  sub_title: string;
  selected: boolean;
  tickets: number;
  type: string;
  price: number;
};
export type ContextApiType = {
  changeNavColor: boolean;
  setChangeNavColor: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTop: () => void;
  userTickets: {
    cartItems: any[];
  };
  setUserTickets: any;
  mobileNumber: string;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  shippingAddressLength: number;
  setShippingAddressLength: React.Dispatch<React.SetStateAction<number>>;
  isShowSideMenu: boolean;
  isLogin: any;
  setIsLogin: React.Dispatch<React.SetStateAction<any>>;
  cartTimer: boolean;
  setCartTimer: React.Dispatch<React.SetStateAction<boolean>>;
  cartSummary: any;
};

export type ScrollContextProps = {
  children: React.ReactNode;
};

export const ContextApiData = createContext<ContextApiType>(
  {} as ContextApiType
);

const CreateContext: React.FC<ScrollContextProps> = ({ children }) => {
  const [changeNavColor, setChangeNavColor] = useState(false);
  const [userTickets, setUserTickets] = useState({ cartItems: [] });
  const [mobileNumber, setMobileNumber] = useState("");
  const [isShowSideMenu, setIsShowSideMenu] = useState(false);
  const [shippingAddressLength, setShippingAddressLength] = useState(0);
  const [isLogin, setIsLogin] = useState<any>(false);
  const orderSummaryToastId = useRef<any>(null);
  const [cartTimer, setCartTimer] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  console.log({ isLogin });
  const navColorHandler = () => {
    if (window.scrollY >= 80) {
      setChangeNavColor(true);
    } else {
      setChangeNavColor(false);
    }
  };

  // order summary promise
  const getTicketsHandler = async () => {
    orderSummaryToastId.current = toast("get summary...", {
      type: "info",
    });
    const { data: response } = await axiosRequest({
      url: order_summary_url,
      headers: {
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // order summary query
  const {
    data: orderSummaryData,
    isLoading,
    isError,
    isFetching,
  } = useQuery<orderSummaryTypes>("order-summary-data", getTicketsHandler, {
    onSuccess: (data) => {
      toast.update(orderSummaryToastId.current, {
        render: "got summary successfully",
        type: "success",
        autoClose: 1000,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isLogin ? true : false,
    onError: () => {
      toast.update(orderSummaryToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  useEffect(() => {
    setIsLogin(LoginUserHandler());
  }, []);

  console.log({ orderSummaryData });
  return (
    <ContextApiData.Provider
      value={{
        changeNavColor,
        setChangeNavColor,
        scrollToTop,
        userTickets,
        setUserTickets,
        mobileNumber,
        setMobileNumber,
        isShowSideMenu,
        setIsShowSideMenu,
        shippingAddressLength,
        setShippingAddressLength,
        isLogin,
        setIsLogin,
        cartSummary: orderSummaryData?.cartSummary.filter(
          ({ cartItems }: any) => cartItems.length !== 0
        ),
        cartTimer,
        setCartTimer,
      }}
    >
      {children}
    </ContextApiData.Provider>
  );
};

export default CreateContext;
