import styled from "styled-components";
import location from "../../../assets/litgalaxyicons/dimmyimage.png";
const WristandStyle = styled.div`
  .wristand__image-container {
    max-width: 685px;
    width: 100%;
    position: relative;
    .wristand__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .wristand__image--2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .wristand__image-container--2 {
    background-image: url(${location});
    background-size: cover;
  }
`;
export default WristandStyle;
