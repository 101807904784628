import styled from "styled-components";
import { lightGrey, PrimaryColor } from "../GlobalStyle";

const SideMenuStyle = styled.div`
  padding-top: 20px;
  .title {
    font-size: 18px;
    color: ${PrimaryColor};
    font-family: "EnnVisionsBold";
  }
  .menu-links {
    &__item {
      a {
        text-decoration: none;
        color: black;
        font-family: "EnnVisionsMedium";
      }
      .active {
        background-color: ${lightGrey};
        padding: 6px 12px;
        width: 100%;
      }
    }
  }
`;

export default SideMenuStyle;
