import styled from "styled-components";
import { tertiaryGrewish } from "../GlobalStyle";

const CustomInputStyle = styled.div`
  width: 100%;
  margin-bottom: 10px;

  label {
    font-size: 13px;
    text-transform: capitalize;
    ////sad
    display: block;
    /* margin-bottom: 10px; */
  }
`;

export default CustomInputStyle;

export const CustomPhoneInput = styled.div<{ padding: string }>`
  label {
    font-family: "EnnVisionsMedium";
  }
  .ant-input-number-group-addon {
    border: none;
    box-shadow: none;
    background: transparent;
    border-left: 1px solid ${tertiaryGrewish};
    border-top: 1px solid ${tertiaryGrewish};
    border-bottom: 1px solid ${tertiaryGrewish};
  }
  .ant-input-number-group .ant-input-number {
    padding: ${(props: any) => props.padding} !important;
    border-right: 1px solid ${tertiaryGrewish} !important;
    border-top: 1px solid ${tertiaryGrewish} !important;
    border-bottom: 1px solid ${tertiaryGrewish} !important;
    border-radius: 0 4px 4px 0 !important;
  }
`;
