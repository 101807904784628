import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { MainHeading, PrimaryColor } from "../../Components/GlobalStyle";
import { Helmet } from "react-helmet";
import LoginStyle from "./style";
import { useMutation } from "react-query";
import {
  Base_Url,
  create_cart,
  Login_User,
  shipping_addresses_url,
} from "../../utils/api_urls";
import axios from "axios";
import { toast } from "react-toastify";
import { LoggedInHandler } from "../../ProtectedRoute";
import { ContextApiData } from "../../utils/CreateContext";
import { useContext, useRef } from "react";
import { axiosRequest } from "../../utils/axios.utils";
import { GetUserShippingAddresses } from "../../hooks/RQHooks";

type loginTypes = {
  email: string;
  password: string;
  terms?: boolean;
};

const initialValues: loginTypes = {
  email: "",
  password: "",
  terms: false,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required!")
    .email("Email should be valid"),
  password: Yup.string().required("Password is required!"),
  terms: Yup.boolean().oneOf([true], "Please accept terms and conditions !"),
});

const Login = () => {
  const navigate = useNavigate();
  const { userTickets, setUserTickets } = useContext(ContextApiData);
  const tickeToastId = useRef<any>(null);
  const { setIsLogin } = useContext(ContextApiData);
  const userShippingAddressToastId = useRef<any>(null);

  let id: any;

  console.log({ userTickets }, "user tickets data");

  // Login User Promise
  const loginUser = async (data: any) => {
    id = toast.loading("Login...");
    const { data: response } = await axios.post(Base_Url + Login_User, data);
    return response.data;
  };

  // login user query
  const { mutateAsync: loginMutation, isLoading } = useMutation(loginUser, {
    onSuccess: (data) => {
      console.log({ data });

      toast.update(id, {
        render: "Login Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      console.log(data);
      localStorage.setItem("litgalaxy:token", JSON.stringify(data));
      setIsLogin(data);
      if (userTickets.cartItems.length !== 0) {
        navigate("/user-shipping-addresses");
      } else {
        navigate("/");
      }
    },
    onError: ({ response: { data } }: any) => {
      console.log({ data }, "data in login");
      if (data.message === "Please Verify Mobile Number.") {
        toast.update(id, {
          render: "Plz Verify Phone",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        navigate("/register-phone");
        return;
      }
      if (!data.success) {
        toast.update(id, {
          render: data.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return;
      }
      toast.update(id, {
        render: "something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    },
  });
  // user shipping addresses promise
  const getShippingAddressesHandler = async () => {
    const { data: response } = await axiosRequest({
      url: shipping_addresses_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  const onSuccess = () => {
    userShippingAddressToastId.current = toast("got addresses successfully", {
      type: "success",
      autoClose: 1000,
    });
  };
  const onError = () => {
    userShippingAddressToastId.current = toast("something went wrong", {
      type: "success",
      autoClose: false,
    });
  };

  // query shipping addresses
  const {
    data: userShippingAddressesData,
    isLoading: shippingAddressLoading,
    isFetching: shippingAddressIsFetching,
    isError: shippingAddressIsError,
  }: any = GetUserShippingAddresses(
    getShippingAddressesHandler,
    onSuccess,
    onError,
    !LoggedInHandler() ? false : true
  );

  // if (isLoading) return <p>Loading...</p>;
  // if (error) return <p>An error has occurred: + {error.message}</p>;

  const onSubmit = async (values: any) => {
    delete values.terms;
    await loginMutation(values);
    if (
      userTickets.cartItems.length > 0 &&
      userShippingAddressesData.length > 0
    ) {
      createCartMutation(userTickets);
      setUserTickets({ cartItems: [] });
    }
  };

  // create cart promise
  const createCartHandler = async (data: any) => {
    tickeToastId.current = toast("cart adding...", {
      type: "info",
    });
    const { data: response } = await axios.post(Base_Url + create_cart, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // create cart mutation
  const { mutate: createCartMutation } = useMutation(createCartHandler, {
    onSuccess: () => {
      toast.update(tickeToastId.current, {
        render: "cart added successfully",
        type: "success",
        autoClose: 1000,
      });
      navigate("/user-cart");
    },
    onError: ({ response: { data } }: any) => {
      if (data.message) {
        toast.update(tickeToastId.current, {
          render: data.message,
          type: "error",
          autoClose: 1000,
        });
        return;
      }
      toast.update(tickeToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* <span className="arrow-box">
        <img src={scroll_up} alt="scroll-up" />
      </span> */}
      <LoginStyle>
        <div className="login-container-card">
          <div className="login-container-card-logo mb-2">
            <MainHeading>Login</MainHeading>
          </div>

          <div className="login-container-card-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                console.log(formik.values);
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <div className="login-input-fields">
                      <FormControl
                        control="input"
                        type="text"
                        name="email"
                        label="Email"
                        placeholder="Email Address"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        labelMarginBottom="8px"
                        className={
                          formik.errors.email && formik.touched.email
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                      <div className="login-input-fields-field">
                        <FormControl
                          control="password"
                          type="text"
                          name="password"
                          label="Password"
                          placeholder="Password"
                          fontFamily="EnnVisionsMedium"
                          labelMarginBottom="8px"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "is-invalid"
                              : "customPasswordInput"
                          }
                        />
                      </div>
                      <p
                        onClick={() => navigate("/reset-email")}
                        className="forget_password mb-0 cursor-pointer"
                      >
                        Forgot your password?
                      </p>
                      <div className="remember-password d-flex mb-2 mt-2 align-items-center cursor-pointer">
                        <input
                          value={formik.values.terms as any}
                          onChange={() =>
                            formik.setFieldValue("terms", !formik.values.terms)
                          }
                          type="checkbox"
                          name="terms"
                          id="terms"
                        />
                        {/* <p className="remember-password-text mb-0 ms-2">
                          Remember me
                        </p> */}
                        <label
                          htmlFor="terms"
                          className="remember-password-text mb-0 ms-2 cursor-pointer"
                        >
                          <span className="me-2">By sign in you</span>
                          <Link to="/terms" className="text-decoration-none">
                            accept terms & conditions
                          </Link>
                        </label>
                      </div>
                      <div>
                        {formik.errors.terms && formik.touched.terms && (
                          <p className="terms">{formik.errors.terms}</p>
                        )}
                      </div>
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="8px"
                        width="100%"
                        type="submit"
                        title="LOG IN"
                        fontSize="14px"
                        fontFamily="EnnVisions"
                        disabled={isLoading}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="signup-text mt-3">
            <p> Don't have an account ?</p>
            <h6 className="ms-1">
              <Link to="/register">Sign Up</Link>
            </h6>
          </div>
        </div>
      </LoginStyle>
    </>
  );
};

export default Login;
