import { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ContextApiData } from "./utils/CreateContext";

type ProtectedRouteProps = {
  children: React.FC;
};

export const LoggedInHandler = () => {
  const tokenData = localStorage.getItem("litgalaxy:token");
  if (tokenData) {
    return JSON.parse(tokenData).token;
  }
};
export const LoginUserHandler = () => {
  const userData = localStorage.getItem("litgalaxy:token");
  if (userData) {
    return userData && JSON.parse(userData);
  }
};
export const LogOutHandler = (): void => {
  localStorage.removeItem("litgalaxy:token");
};

const ProtectedRoute: any = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isLogin, setIsLogin } = useContext(ContextApiData);

  // user token handler
  const userTokenHandler = () => {
    const tokenData = localStorage.getItem("litgalaxy:token");
    const userTokenData = JSON.parse(tokenData!);
    return userTokenData;
  };

  const pathName = location.pathname.split("/")[1];
  const unProtectedurls = [
    "login",
    "register",
    "register-phone",
    "verify-otp",
    "editPhone",
    "terms",
  ];
  if (
    unProtectedurls.includes(pathName) &&
    isLogin &&
    isLogin.mobileVerified !== 0
  ) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoute;
