import HeroStyle from "./style";
import mainImg from "../../../assets/images/main-img.jpg";

const Hero = () => {
  return (
    <HeroStyle className="mb-3 hero">
      <figure className="m-0 hero__image-container">
        <img src={mainImg} alt="image of hero" className="hero__image" />
      </figure>

      <h6 className="my-3 hero__subheading">Music</h6>
      <h5 className="hero__heading">Lit Galaxy Festival</h5>
      <p className="hero__details">
        Unique rum-based cocktails and Live Music ranging from easy-listening to
        country to covers on Saturday Night at Romero Distilling Co.!
      </p>
    </HeroStyle>
  );
};

export default Hero;
