import minus from "../../../assets/litgalaxyicons/ic_tickets_add.svg";
import plus from "../../../assets/litgalaxyicons/ic_tickets_remove.svg";
import EarlybIrdtIcketsStyle from "./style";

type ticketsDataTypes = {
  heading: string;
  sales_end: string;
  title: string;
  tickets: number;
  selected: boolean;
  price: number;
};

type earlyBirdTicketsProps = {
  index: number;
  addTicketsHandler: (index: number, title: string) => void;
  selectedCardHandler: (index: number) => void;
  ticketsData: ticketsDataTypes;
};
const EarlyBirdTickets: React.FC<earlyBirdTicketsProps> = ({
  index,
  addTicketsHandler,
  ticketsData: { heading, sales_end, tickets, title, selected, price },
  selectedCardHandler,
}) => {
  return (
    <EarlybIrdtIcketsStyle
      key={index}
      selected={selected}
      className="Earlybirdtickets my-4 cursor-pointer"
      onClick={() => selectedCardHandler(index)}
    >
      <div className="Earlybirdtickets__container">
        <h3 className="mb-0 Earlybirdtickets__heading text-center">
          {heading}
        </h3>
        <div className="d-flex align-items-center justify-content-between py-lg-0 py-3">
          <img
            className="cursor-pointer"
            onClick={() => addTicketsHandler(index, "minus")}
            src={minus}
            alt="minus-icon"
          />
          <p className="mb-0 mx-3 Earlybirdtickets__quatity">{tickets}</p>
          <img
            className="cursor-pointer"
            onClick={() => addTicketsHandler(index, "add")}
            src={plus}
            alt="plus-icon"
          />
        </div>
      </div>
      <hr />
      <div className="Earlybirdtickets__stage">
        <h6 className="mb-0 Earlybirdtickets__subheading">{title}</h6>
        <p className="mb-0 Earlybirdtickets__price">$ {tickets * price}</p>
      </div>
      <p className="Earlybirdtickets__tertiaryheading">{sales_end}</p>
    </EarlybIrdtIcketsStyle>
  );
};

export default EarlyBirdTickets;
