import { paymentSummaryTypes } from "../../../Screens/litgalaxy/OrderSummary/OrderSummary";
import { PrimaryHeading2 } from "./../../GlobalStyle";
import PaymenySummaryStyle from "./style";

type paymentSummaryProps = {
  data?: paymentSummaryTypes;
};

const PaymentSummary = ({ data }: paymentSummaryProps) => {
  const Item = ({ title, quantity }: { title: string; quantity: number }) => (
    <div className="d-flex justify-content-between align-items-center">
      <p className="mb- paymeny-summary__payment-name">{title}</p>
      <h5 className="mb- paymeny-summary__payment-price-cad">${quantity}</h5>
    </div>
  );
  return (
    <PaymenySummaryStyle>
      <PrimaryHeading2 className="mb-4">Payment Summary</PrimaryHeading2>
      <div className="paymeny-summary__container mt-2">
        {data?.paymentBreakdown?.map(
          ({ categoryName, subTotal, wristBandQuantity }, index) => {
            return (
              <div key={index}>
                {wristBandQuantity > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb- paymeny-summary__payment-name">
                      {wristBandQuantity}X {categoryName}
                    </p>
                    <h5 className="mb- paymeny-summary__payment-price-cad">
                      ${subTotal}
                    </h5>
                  </div>
                )}
              </div>
            );
          }
        )}
        <Item title="Delivery Fee" quantity={data?.deliveryFee as number} />
        <Item title="SubTotal" quantity={data?.subTotal as number} />
        <Item title="Tax" quantity={data?.tax as number} />
        <Item title="Service Fee" quantity={data?.serviceFee as number} />
        <hr />

        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 paymeny-summary__grand-total">Grand Total</p>
          <h5 className="mb-0 paymeny-summary__price-cad">
            ${data?.grandTotal} CAD
          </h5>
        </div>
      </div>
    </PaymenySummaryStyle>
  );
};

export default PaymentSummary;
