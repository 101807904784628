import styled from "styled-components";
import { backImageProps } from "./GlobalTypes";

export const size = {
  mobileS: "334px",
  mobileM: "375px",
  mobileL: "425px",
  mobilexL: "480px",
  tablet: "768px",
  tabletL: "991px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const minWidthDevice = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobilexL: `(min-width: ${size.mobilexL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const maxWidthDevice = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobilexL: `(max-width: ${size.mobilexL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const BasicColor = "#C0922E";
export const PrimaryColor = "#880085";
export const whiteColor = "#ffffff";
export const whiteColor2 = "#F8F8F8";
export const whiteColor3 = "#EFEFF4";

export const pureDark = "#000000";

export const lightDark = "#4D4D4D";
export const secondaryDark = "#221E30";
export const secondaryGrey = "#AAB2BA";
export const secondaryDark2 = "#1C1C1E";

export const secondaryDark3 = "#1B283F";

export const secondaryDark4 = "#4F5460";
export const secondaryDark5 = "#393939";
export const tertiaryDark = "#1A1B1E";
export const tertiaryDark2 = "#1A1A1A";
export const tertiaryDark3 = "#212121";

// new
export const lightGrey = "#d6d6e0";
export const lightGrey2 = "#464E5F";
export const lightGrey3 = "#707070";
export const lightGrey4 = "#D1D1D6";
export const lightGrey5 = "#E9E9E9";
export const lightGrey6 = "#717272";
export const lightGrey7 = "#efeff4";
export const lightGrey8 = "#E9E9E9";
export const lightGrey9 = "#0D0E10";
export const lightGrey10 = "#e5e2e2";

export const secondaryGreen = "#32D74B";
export const greenishColor = "#34C759";
export const tertiaryGrey = "#808896";
export const tertiaryGrey2 = "#80808F";
export const tertiaryGrey3 = "#B5B5C3";
export const tertiaryGrey4 = "#AEAEB2";
export const tertiaryGrey5 = "#E5E5EA";
export const tertiaryGrey6 = "#D6D6E0";
export const tertiaryGrey7 = "#232837";
export const tertiaryGrey8 = "#FCFBFB";
export const tertiaryGrey10 = "#FAFAFA";
export const tertiaryGrey11 = "#00B0E9";
export const tertiaryGrey12 = "#8E8E93";
export const tertiaryGrey13 = "#ECF0F3";
export const tertiaryGrey14 = "#717272";
export const tertiaryGrey15 = "#FCFBFBB3";
export const tertiaryGrey16 = "#F8F8F8";
export const tertiaryGrey17 = "#D1D1D6";
export const tertiaryGrey18 = "#F3F3F3";
export const tertiaryGrey19 = "#EBEBEB";
export const tertiaryGrey20 = "#606060";
export const tertiaryGrey21 = "#464E5F";
export const tertiaryGrey22 = "#6F7177";
export const lightColor1 = "#F5F5F5";

export const secondaryBlue = "#0A84FF";
export const lightblue = "#04A4D8";
export const lightblue2 = "#049CCE";
export const lightBlue3 = "#049CCE";

export const tertiaryGrewish = "#C6C6C8";
export const lightdarkblue = "#00000029";
export const tertiaryGrewish2 = "#C7C7CC";
export const darkbluelight = "#1A273D";
export const darkbluelight2 = "#1B283F";
export const OrangeColor = "#E05417";
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const MainHeading = styled.div`
  font-size: 21px;
  color: ${PrimaryColor};
  font-family: "EnnVisionsMedium";

  @media screen and (max-width: 991px) {
    font-size: 19px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  .ant-btn {
    background-color: ${(props: any) => props.bkColor};
    color: ${(props: any) => props.color};
    width: ${(props: any) => props.width};
    padding: ${(props: any) => props.padding};
    font-size: 16px;
    height: auto;
  }
`;
export const TertiaryHeading = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  color: ${lightGrey2};
  @media screen and (max-width: 480px) {
    font-size: 17px;
    line-height: 21px;
    font-weight: bold;
  }
`;
export const InputErrorMessage = styled.div`
  color: ${PrimaryColor};
  text-align: start;
  margin-left: 3px;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 5px;
`;

export const SecondaryHeading = styled.h5`
  color: ${pureDark};
  font-family: "EnnVisionsMedium";
  font-size: 40px;
  @media screen and (max-width: 991px) {
    font-size: 30px;
  }
  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const SecondaryHeadingMini = styled.h5`
  color: ${pureDark};
  font-family: "EnnVisionsMedium";
  font-size: 31px;
  @media screen and (max-width: 991px) {
    font-size: 26px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const TertiraryHeading = styled.h6`
  color: ${pureDark};
  font-size: 20px;
  font-family: "EnnVisionsMedium";
  margin-bottom: 0;
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
export const TertiraryHeading1 = styled.h6`
  color: ${lightDark};
  font-size: 20px;
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
export const TertiraryHeadingMini = styled.h6`
  color: ${secondaryDark5};
  font-size: 18px;
  font-family: "EnnVisionsMedium";

  @media screen and (max-width: 991px) {
    font-size: 17px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const TetraHeading = styled.div`
  font-size: 16px;
  color: ${tertiaryGrey7};
  font-family: "EnnVisionsMedium";
`;
export const MiniHeading = styled.p`
  font-size: 16px;
  color: ${pureDark};
  font-family: "EnnVisionsMedium";
  margin-bottom: 0;
`;

export const MiniHeadingSecondary = styled.p`
  font-size: 16px;
  color: ${pureDark};
  font-family: "EnnVisionsMedium";
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  background: #e5eaee;
  border-radius: 5px;
  width: 240px;
  height: 45px;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    filter: brightness(150%);
  }
  &:active {
    transform: translateX(10px);
    filter: sepia(10%);
  }
  span {
    text-align: center;
    font-size: 16px;
    font-family: "EnnVisionsMedium";
    color: #1b283f;
    text-transform: uppercase;
  }
`;
export const ColoredButton = styled(Button)`
  background: #880085;
  span {
    color: ${whiteColor};
  }
`;

export const BackgroundImage = styled.div<backImageProps>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
    url(${(props) => `\" ${props.url} \"`});
  background-repeat: ${(props) => props.repeat};
  background-size: ${(props) => props.size};
  background-position: ${(props) => props.postion};
  transition: all 1s ease-in-out;
  height: ${(props) => props.height};
  cursor: pointer;
`;

export const BackgroundImageLight = styled.div<backImageProps>`
  background: url(${(props) => `\" ${props.url} \"`});
  background-repeat: ${(props) => props.repeat};
  background-size: ${(props) => props.size};
  background-position: ${(props) => props.postion};
  transition: all 1s ease-in-out;
  height: ${(props) => props.height};
  cursor: pointer;
`;
// lit galaxy
export const PrimaryHeading = styled.h2`
  font-family: "EnnVisionsMedium";
  font-size: 19px;
  text-transform: capitalize;
  color: ${pureDark};
`;
export const PrimaryHeading2 = styled.h3`
  text-align: left;
  font-family: "EnnVisionsMedium";
  font-size: 20px;
  letter-spacing: 0.12px;
  text-transform: capitalize;
  color: ${pureDark};
`;
export const Primarypara = styled.p<any>`
  font-size: 14px;
  font-family: "EnnVisions";
  letter-spacing: 0.08px;
  color: ${lightGrey2};
  span {
    color: ${pureDark};
    font-family: ${(props) =>
      props.boldFamily ? "EnnVisionsBold" : "EnnVisions"};
  }
`;
export const Tertiaryheading = styled.h6`
  font-family: "EnnVisionsMedium";
  font-size: 16px;
  letter-spacing: 0.1px;
`;
