import styled from "styled-components";

const UserCartyStyle = styled.div`
  .left-side {
    padding-bottom: 60px;
  }
  .place-order-btn {
    width: 45%;
  }
`;

export default UserCartyStyle;
