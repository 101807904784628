import { useQuery } from "react-query";
import { LoggedInHandler } from "../ProtectedRoute";

// query shipping addresses

export const GetUserShippingAddresses = (
  getShippingAddressesHandler: any,
  onSuccess: any,
  onError: any,
  isEnable: boolean = true
) => {
  return useQuery("user-shipping-addresses", getShippingAddressesHandler, {
    onSuccess,
    keepPreviousData: true,
    onError,
    refetchOnWindowFocus: false,
    enabled: isEnable,
  });
};
