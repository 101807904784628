import Card from "../../Components/CreditCard/CreditCard";
import { DeleteOutlined } from "@ant-design/icons";
import CreditCardsStyle from "./style";
import { PrimaryHeading2 } from "../../Components/GlobalStyle";
import { useRef } from "react";
import { toast } from "react-toastify";
import { axiosRequest } from "../../utils/axios.utils";
import { LoggedInHandler } from "../../ProtectedRoute";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { user_credit_cards } from "../../utils/api_urls";
import Loader from "../../Components/Loader/Loader";
import warningImg from "../../assets/images/warning.png";
import { useParams } from "react-router-dom";
import NoDataFound from "../litgalaxy/UserShippingAddresses/NoDataFound";

type creditCardTypes = {
  id: number;
  holder_name: string;
  postal_code: string;
  expiry_month: number;
  expiry_year: number;
  brand: string;
  last4: string;
  is_default: number;
  user_id: number;
  created_at: "2023-01-23T16:03:18.000000Z";
  updated_at: "2023-01-23T16:03:18.000000Z";
  cartNumber: string;
}[];

const CreditCards = () => {
  const creditCardsToastId = useRef<any>(null);
  const { creditCardId } = useParams();
  const deleteCardToastId = useRef<any>(null);
  const queryClient = useQueryClient();

  // credit cards promise
  const getCreditCardHandler = async () => {
    creditCardsToastId.current = toast("getting data...", {
      type: "info",
    });
    const { data: response } = await axiosRequest({
      url: user_credit_cards,
      headers: {
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // delete credit card promise
  const deteleCreditCardHandler = async (id: number) => {
    creditCardsToastId.current = toast("deleting card...", {
      type: "warning",
    });
    const { data: response } = await axiosRequest({
      url: user_credit_cards + "/" + id + "/delete",
      headers: {
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // credit cards get query
  const {
    data: creditCardsData,
    isLoading,
    isError,
    isFetching,
  } = useQuery<creditCardTypes>("get-credit-cards", getCreditCardHandler, {
    onSuccess: (data) => {
      toast.update(creditCardsToastId.current, {
        render: "got data successfully",
        type: "success",
        autoClose: 1000,
      });
    },
    refetchOnWindowFocus: false,
    onError: () => {
      toast.update(creditCardsToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });
  // detlete credit card query
  const { mutate: deleteCardMutation }: any = useMutation(
    deteleCreditCardHandler,
    {
      onSuccess: (data: any) => {
        toast.update(deleteCardToastId.current, {
          render: "deleted successfully",
          type: "warning",
          autoClose: 1000,
        });
        queryClient.invalidateQueries("get-credit-cards");
      },
      onError: ({ response: { data } }: any) => {
        console.log({ data });
        toast.update(deleteCardToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
    }
  );

  if (isLoading || isFetching) return <Loader />;
  if (isError)
    return (
      <article>
        <img src={warningImg} alt="error" />
        <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
      </article>
    );

  return (
    <CreditCardsStyle>
      {creditCardsData?.length === 0 ? (
        <NoDataFound title="No Card Added Yet" />
      ) : (
        <>
          <PrimaryHeading2>Credit Cards</PrimaryHeading2>
          {creditCardsData?.map(({ id, cartNumber, brand, holder_name }) => (
            <div
              key={id}
              className="d-flex justify-content-between align-items-center credit-card"
            >
              <Card
                brand={brand}
                holder_name={holder_name}
                cartNumber={cartNumber}
              />
              <DeleteOutlined
                className="fs-4 ms-2 mt-2 mb-3"
                onClick={() => {
                  deleteCardMutation(id);
                }}
              />
            </div>
          ))}
        </>
      )}
    </CreditCardsStyle>
  );
};

export default CreditCards;
