import {
  ColoredButton,
  PrimaryColor,
  PrimaryHeading,
  PrimaryHeading2,
} from "../../../Components/GlobalStyle";
import { Row, Col } from "react-bootstrap";
import Ticketinfo from "../../../Components/litgalaxys/ticketinfo/Ticketinfo";
import QTY from "../../../Components/litgalaxys/QTY/QTY";
import login6image from "../../../assets/litgalaxyicons/main-img.jpg";
import { useNavigate } from "react-router-dom";
import UserCartyStyle from "./style";
import { useContext, useEffect, useRef, useState } from "react";
import { axiosRequest } from "../../../utils/axios.utils";
import {
  Base_Url,
  create_cart,
  delete_whole_cart,
  delete_single_cart_item,
  user_cart_items,
} from "../../../utils/api_urls";
import Loader from "../../../Components/Loader/Loader";
import warningImg from "../../../assets/images/warning.png";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { toast } from "react-toastify";
import { LoggedInHandler } from "../../../ProtectedRoute";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import NoDataFound from "../UserShippingAddresses/NoDataFound";
import axios from "axios";
import { ContextApiData } from "../../../utils/CreateContext";
import CustomModal from "../../../Components/Modal/CustomModal";
import DeleteConfirmation from "../../../Components/DeleteConfirmation/DeleteConfirmation";

export type cart_item_wristband = {
  id: number;
  status: string;
  balance: number;
  nfc_unique_key: string;
  is_deleted: number;
  deleted_on: null;
  category_id: number;
  user_id: number;
  deleted_by: null;
  created_by: null;
  updated_by: null;
  created_at: null;
  updated_at: "2023-01-22T16:47:35.000000Z";
};
export type cart_item_shipping_address = {
  id: number;
  shippingAddress: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    address_line_1: string;
    address_line_2: string;
    country_id: number;
    state_id: number;
    city_id: number;
    postal_code: string;
    phone_number: string;
    user_id: number;
    latitude: "37.428135080";
    longitude: "-122.079254220";
    created_at: "2023-01-22T16:45:23.000000Z";
    updated_at: "2023-01-22T16:45:23.000000Z";
  };
  wristband: cart_item_wristband;
};

export type cart_item_types = {
  description: string;
  id: number;
  name: string;
  quantity: number;
  retail_price: number;
  cartItems: cart_item_shipping_address[];
};

const UserCart = () => {
  const navigate = useNavigate();

  const userCartItemsToastId = useRef<any>(null);
  const deleteCartToastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const [isShowModal, setIsShowModal] = useState(false);
  const { setCartTimer } = useContext(ContextApiData);

  //  user cart items Promise
  const getTicketsHandler = async () => {
    const { data: response } = await axiosRequest({
      url: user_cart_items,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response;
  };
  // user cart item query
  const {
    data: userCartItemsData,
    isLoading,
    isError,
  } = useQuery("user-cart-items", getTicketsHandler, {
    onSuccess: (data) => {
      console.log({ data });
      // checking cartItems length of items
      const cartItems = data.filter(
        ({ cartItems }: any) => cartItems.length !== 0
      );
      if (cartItems.length > 0) {
        setCartTimer(true);
      }
      // userCartItemsToastId.current = toast("got cart items successfully", {
      //   type: "success",
      //   autoClose: 1000,
      // });
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: () => {
      setCartTimer(false);
      userCartItemsToastId.current = toast("got cart items successfully", {
        type: "success",
        autoClose: false,
      });
    },
  });
  // delete whole cart

  // delete whole cart promise
  const deleteCartHandler = async () => {
    deleteCartToastId.current = toast("deleting wholte cart...", {
      type: "info",
    });
    const { data: response } = await axios.get(Base_Url + delete_whole_cart, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // delete whole cart mutation
  const { mutateAsync: deleteCartMutation } = useMutation(deleteCartHandler, {
    onSuccess: () => {
      toast.update(deleteCartToastId.current, {
        render: "deleted cart item successfully",
        type: "success",
        autoClose: 1000,
      });
      queryClient.invalidateQueries("user-cart-items");
    },
    onError: ({ response: { data } }: any) => {
      toast.update(deleteCartToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  // if (isLoading || isFetching) return <Loader />;
  // if (isError)
  //   return (
  //     <article>
  //       <img src={warningImg} alt="error" />
  //       <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
  //     </article>
  //   );
  // if (userCartItemsData.length < 1)
  //   return <NoDataFound title="No Cart Item Available" />;
  // clear cart handler in modal
  const deleteCart = async () => {
    await deleteCartMutation();
    queryClient.invalidateQueries("user-cart-items");
  };

  return (
    <UserCartyStyle>
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
      >
        <DeleteConfirmation
          setIsShowModal={setIsShowModal}
          title="Do You Want Clear Cart?"
          handleConfirmation={deleteCart}
        />
      </CustomModal>
      <Row className="flex-wrap-reverse mt-2">
        <Col md={8} className="d-flex flex-column position-relative left-side">
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <article>
              <img src={warningImg} alt="error" />
              <p className="text-center fs-5 mt-2 fw-bold">
                something went wrong
              </p>
            </article>
          ) : userCartItemsData.length < 1 ? (
            <NoDataFound title="No Cart Item Available" />
          ) : (
            <>
              <PrimaryHeading className="fs-4">User Cart</PrimaryHeading>
              {userCartItemsData.length > 0 && (
                <div className="ms-auto">
                  <CustomButton
                    bgcolor={PrimaryColor}
                    color="white"
                    padding="6px"
                    width="160px"
                    type="submit"
                    title="Clear Cart"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    clicked={() => setIsShowModal(true)}
                  />
                </div>
              )}
              {userCartItemsData.map(
                ({
                  cartItems,
                  description,
                  id,
                  quantity,
                  name,
                  retail_price,
                }: cart_item_types) => (
                  <div
                    key={id}
                    className={`${
                      cartItems.length === 0 ? "d-none" : "d-block"
                    }`}
                  >
                    <QTY number={quantity} title={name} />
                    {cartItems &&
                      cartItems.map((data) => (
                        <Ticketinfo
                          description={description}
                          price={retail_price}
                          name={name}
                          key={data.id}
                          id={data.id}
                          cart_item_data={data}
                        />
                      ))}
                    {/* <Ticketinfo />
            <QTY number={2} title="VIP" />
            <Ticketinfo />
            <QTY number={3} title="VVIP" />
            <Ticketinfo /> */}
                  </div>
                )
              )}
              {userCartItemsData.length > 0 && (
                <ColoredButton
                  className="align-self-end my-3 position-fixed bottom-0 place-order-btn"
                  onClick={() => navigate("/order-summary")}
                >
                  <span>PLACE ORDER</span>
                </ColoredButton>
              )}
            </>
          )}
        </Col>

        <Col md={4} className=" my-3 mx-auto d-flex">
          <figure className="mb-3 mx-auto">
            <img src={login6image} alt="" />
          </figure>
        </Col>
      </Row>
    </UserCartyStyle>
  );
};

export default UserCart;
