import styled from "styled-components";
import {
  darkbluelight,
  lightGrey,
  lightGrey10,
  lightGrey2,
} from "../../GlobalStyle";

const WhenandwhereStyle = styled.div`
  .when-and-where-box {
    border: 0.5px solid ${lightGrey};
    border-radius: 2px;
    padding: 0 18px 18px 18px;
    .right-border {
      border-right: 1px solid ${lightGrey10};
    }
    &-card {
      border-bottom: 1px solid ${lightGrey10};
      margin-top: 14px;
    }
  }
  .when__heading {
    font-family: "EnnVisionsMedium";
    font-size: 16px;
    color: ${darkbluelight};
  }
  .when__subheading {
    font-size: 14px;
    font-family: "EnnVisions";
    color: ${lightGrey2};
    span {
      display: block;
    }
  }
`;
export default WhenandwhereStyle;
