import styled from "styled-components";
import {
  darkbluelight,
  lightGrey,
  lightGrey2,
  lightGrey7,
  secondaryDark3,
  tertiaryGrey16,
  whiteColor,
} from "../../GlobalStyle";

const UserTicketsStyle = styled.div`
  .user__ticket__container {
    background: ${whiteColor};
    border: 0.5px solid ${lightGrey};
    border-radius: 2px;
    height: 50vh;
    overflow-y: auto;
  }
  .user__ticket__tickets {
    display: flex;
    border-bottom: 0.5px solid ${lightGrey};
    padding: 5px;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  .user__ticket__tickets-detail {
    flex: 1;
    padding: 5px;
  }
  .user__ticket__tickets-name,
  .user__ticket__person-name {
    text-align: left;
    font-size: 16px;
    font-family: "EnnVisionsMedium";
    color: ${darkbluelight};
  }
  .user__ticket__ticket-number-id,
  .user__ticket__ticket-wristband-id {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisionsMedium";
    color: ${secondaryDark3};
  }
  .user__ticket__ticket-date,
  .user__ticket__person-address,
  .user__ticket__person-email {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisions";
    color: ${lightGrey2};
  }
  .user__ticket__person-info {
    padding: 5px;
    background: ${tertiaryGrey16};
    border: 1px solid ${lightGrey7};
    border-radius: 4px;
    flex: 1;
  }
  .user__ticket__person-status {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisionsMedium";
  }
  .user__ticket__person-info {
    position: relative;
    .user__ticket__person-info-edit {
      position: absolute;
      top: 5%;
      right: 7%;
    }
  }
  .btn-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 25px;
  }
`;
export default UserTicketsStyle;
