import { LoggedInHandler } from "../ProtectedRoute";

export const Base_Url = "https://api.lgf2000.com/api/";

// authorization urls
export const Register_User = "registerUser";
export const Login_User = "login";
export const Create_Wristbands = "create_wristband";
export const User_Owned_Writsbrands = "wristbands/2/list";
export const send_Otp = "send-code";
export const verify_Mobile_Otp = "verify";
export const tickets_data_url = "v1/wristband_categories";

// shipping address urls
export const shipping_addresses_url = "v1/shipping_addresses";
export const update_shipping_address_url = "v1/shipping_address/";
export const delete_shipping_address_url = "v1/shipping_address/";
export const shipping_address_details_url = "v1/shipping_address/";

// cart urls

export const create_cart = "v1/cart";
export const update_quantity = "v1/cart/update/quantity";
export const update_address = "v1/cart/update/address";
export const user_cart_items = "v1/cart";
export const delete_whole_cart = "v1/cart/delete";
export const delete_single_cart_item = "v1/cartItem/";

// order summary urls

export const order_summary_url = "v1/order/summary";

// credit card urls
export const credit_card_url = "v1/bank_card";
export const user_credit_cards = "v1/bank_card";

// charge on card

export const charge_on_card = "v1/order/checkout";

// headers
export const aunthenticationHeader = "application/json";
export const content_Type_Header = "application/json";

// user dashboard

export const user_dashboard_url = "v1/user/dashboard";

// cart time handler

export const remaining_time_url = "v1/time/remaining";
export const extend_time_url = "v1/time/extend";

// reset email urls

export const reset_email_url = "v1/password/reset";
export const verify_email__code_url = "v1/verify/reset-code";

// export const aunthenticationHeader = {
//   Accept: "application/json",
// };
// export const content_Type_Header = {
//     "Content-Type": "application/json",
// };

export const authorizationHeader = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
};
