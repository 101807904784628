import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { MapMeasurements } from "../../utils/types";
import MapBoxStyle from "./style";

const MapBox: React.FC<MapMeasurements> = ({ width, height }) => {
  const position = ["51.505", -"0.09"];
  return (
    <MapBoxStyle width={width} height={height}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={false}
        id="map"
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <Marker position={position}/>
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup> */}
      </MapContainer>
    </MapBoxStyle>
  );
};

export default MapBox;
