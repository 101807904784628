import { Col, Row } from "react-bootstrap";
import HeroAbout from "../../Components/AboutEventComponents/HeroSection/HeroAbout";
import LiveUpdate from "../../Components/AboutEventComponents/LiveUpdate/LiveUpdate";
import { useRef } from "react";
import { toast } from "react-toastify";
import { axiosRequest } from "../../utils/axios.utils";
import { LoggedInHandler } from "../../ProtectedRoute";
import { user_dashboard_url } from "../../utils/api_urls";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader/Loader";
import warningImg from "../../assets/images/warning.png";
import UserTickets from "../../Components/AboutEventComponents/UserTickets/UserTickets";

const UserDashboard = () => {
  const userDashboardId = useRef<any>();
  // order summary promise
  const getTicketsHandler = async () => {
    userDashboardId.current = toast("get dashboard data...", {
      type: "info",
    });
    const { data: response } = await axiosRequest({
      url: user_dashboard_url,
      headers: {
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // order summary query
  const {
    data: userDashboardData,
    isLoading,
    isError,
    isFetching,
  } = useQuery("user-dashboard-data", getTicketsHandler, {
    onSuccess: (data) => {
      toast.update(userDashboardId.current, {
        render: "got summary successfully",
        type: "success",
        autoClose: 1000,
      });
    },
    refetchOnWindowFocus: false,
    onError: () => {
      toast.update(userDashboardId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  // is loading and getting error
  // if (isLoading || isFetching) return <Loader />;
  // if (isError)
  //   return (
  //     <article>
  //       <img src={warningImg} alt="error" />
  //       <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
  //     </article>
  //   );
  // if (orderSummaryData && orderSummaryData?.cartSummary.length < 1) {
  //   return <NoDataFound title="No Data Available" />;
  // }

  return (
    <Row>
      <Col md={8}>
        {/* <HeroAbout /> */}
        {isLoading || isFetching ? (
          <Loader />
        ) : isError ? (
          <article>
            <img src={warningImg} alt="error" />
            <p className="text-center fs-5 mt-2 fw-bold">
              something went wrong
            </p>
          </article>
        ) : (
          <UserTickets userDashboardData={userDashboardData} />
        )}
      </Col>
      <Col md={4}>
        <LiveUpdate />
      </Col>
    </Row>
  );
};

export default UserDashboard;
