import ShippingAddressCardStyle from "./style";
import profile from "../../../assets/litgalaxyicons/ic_assign_address.svg";
import editIcon from "../../../assets/litgalaxyicons/ic_edit.svg";
import { userShippingAddressTypes } from "../../../Screens/litgalaxy/UserShippingAddresses/UserShippingAddresses";
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {
  Base_Url,
  delete_shipping_address_url,
  update_address,
} from "../../../utils/api_urls";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { DeleteOutlined } from "@ant-design/icons";
import CustomModal from "../../Modal/CustomModal";
import DeleteConfirmation from "../../DeleteConfirmation/DeleteConfirmation";

type ShippingAddressProps = {
  shippingAddressesLength: number;
  data: userShippingAddressTypes;
};

const ShippingAddress = ({
  shippingAddressesLength,
  data: {
    address_line_1,
    address_line_2,
    first_name,
    last_name,
    email,
    phone_number,
    id,
    country: { name },
  },
}: ShippingAddressProps) => {
  const navigate = useNavigate();
  const deleteShippingAddressToastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const { cartItemId } = useParams();
  const updateCartAddressToastId = useRef<any>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  // shipping address id from router param
  const { addressId } = useParams();

  // delte shipping address promise
  const deleteShippingAddressHandler = async (id: number) => {
    deleteShippingAddressToastId.current = toast("deleting address...", {
      type: "info",
    });
    const { data: response } = await axios.get(
      Base_Url + delete_shipping_address_url + id + "/delete",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // delete shipping address mutation
  const { mutate }: any = useMutation(deleteShippingAddressHandler, {
    onSuccess: (data: any) => {
      toast.update(deleteShippingAddressToastId.current, {
        render: "deleting successfully",
        type: "warning",
        autoClose: 1000,
      });
      queryClient.invalidateQueries("user-shipping-addresses");
    },
    onError: ({ response: { data } }: any) => {
      console.log({ data });
      toast.update(deleteShippingAddressToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  // navigate to update address
  const navigateToAddressUpdate = (id: number) => {
    navigate(`/update-shipping-address/${id}`);
  };

  // Update Cart Address

  // update cart address promise
  const updateCartAddressHandler = async (data: any) => {
    updateCartAddressToastId.current = toast("cart updating...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + update_address,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // update cart address mutation
  const { mutate: updateCartAddressMutate } = useMutation(
    updateCartAddressHandler,
    {
      onSuccess: () => {
        toast.update(updateCartAddressToastId.current, {
          render: "address update successfully",
          type: "success",
          autoClose: 1000,
        });
        navigate("/user-cart");
      },
      onError: ({ response: { data } }: any) => {
        toast.update(updateCartAddressToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
    }
  );

  // update cart address handler
  const handleUpdateCartAddress = (addressId: number) => {
    updateCartAddressMutate({
      cart_item_id: +cartItemId!,
      shipping_address_id: addressId,
    });
  };
  // delete shipping address
  const deleteShippingAddress = () => {
    if (shippingAddressesLength === 1) {
      deleteShippingAddressToastId.current = toast(
        "can't delete primary address...",
        {
          type: "error",
        }
      );
    } else {
      mutate(addressId);
    }
  };
  return (
    <ShippingAddressCardStyle
      className="shipping-address-card my-3"
      // onClick={() => navigateToAddressDetails(id)}
    >
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
      >
        <DeleteConfirmation
          setIsShowModal={setIsShowModal}
          title="Do you want delete address?"
          handleConfirmation={deleteShippingAddress}
        />
      </CustomModal>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="shipping-address-card__heading">{name}</h5>
        {cartItemId && (
          <button
            className="btn btn-success"
            onClick={() => handleUpdateCartAddress(id)}
          >
            update address
          </button>
        )}

        {shippingAddressesLength === 1 && (
          <button className="btn btn-outline-secondary">Primary Address</button>
        )}
        <figure className="d-flex">
          <img
            onClick={() => navigateToAddressUpdate(id)}
            src={editIcon}
            alt="edit icon"
            className="cursor-pointer me-3"
          />
          <img
            src={profile}
            alt="image of profile"
            className="cursor-pointer me-3"
          />
          <DeleteOutlined
            className={`fs-3 ms-2 mt-1`}
            onClick={() => {
              navigate(`/user-shipping-addresses/${id}`);
              setIsShowModal(true);
            }}
          />
        </figure>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-md-row flex-column">
        <h5 className="shipping-address-card__name">
          {first_name} {last_name}
        </h5>
        <p className="shipping-address-card__email">{email}</p>
        <p className="shipping-address-card__phone">{phone_number}</p>
      </div>
      <p className="shipping-address-card__address">
        {address_line_1} {address_line_2}
      </p>
    </ShippingAddressCardStyle>
  );
};

export default ShippingAddress;
