import { InputNumber } from "antd";
import flag from "../../assets/icons/ic_flag.png";
import { CustomPhoneInput } from "./style";

type CustomInputNumberProps = {
  value: string;
  formik: any;
  padding?: string;
  showLabel?: boolean;
  label?: string;
  marginTop?: string;
  placeholder: string;
  name?: string;
};
const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  value,
  formik,
  padding = "5px",
  showLabel = false,
  label = "Mobile",
  marginTop = "4px",
  placeholder,
  name = "mobile",
}) => {
  return (
    <CustomPhoneInput padding={padding}>
      {showLabel && (
        <label htmlFor="" className="my-1">
          {label}
        </label>
      )}
      <InputNumber
        style={{
          border: "none",
          height: "40px",
          marginTop: marginTop,
        }}
        className="w-100 border-0"
        controls={false}
        name="phone"
        value={value}
        placeholder={placeholder}
        onChange={(e) => formik.setFieldValue(name, e?.toString())}
        addonBefore={
          <div
            style={{ width: "40px", height: "40px" }}
            className="d-flex align-items-center"
          >
            <img src={flag} alt="flag" className="me-2" />
            <p className="m-0 fw-bold">+ 1</p>
          </div>
        }
      />
    </CustomPhoneInput>
  );
};

export default CustomInputNumber;
