import styled from "styled-components";
import { maxWidthDevice, tertiaryGrey8 } from "../../../Components/GlobalStyle";

const TermsAndConditionsStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: ${tertiaryGrey8};
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
  @media ${maxWidthDevice.mobileL} {
    padding: 10px;
    height: auto;
  }
`;

export default TermsAndConditionsStyle;
