import { Formik } from "formik";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { PrimaryColor, tertiaryGrewish } from "../../Components/GlobalStyle";
import VerifyOtpStyle from "./style";
import * as Yup from "yup";
import FormControl from "../../Components/FormControl";
import { useNavigate } from "react-router-dom";
import AuthenticateText from "../../Components/AuthenticationText/Index";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
import { Base_Url, verify_Mobile_Otp } from "../../utils/api_urls";
import { Form } from "antd";
import { ContextApiData } from "../../utils/CreateContext";
import { useContext, useRef, useState } from "react";
import CustomModal from "../../Components/Modal/CustomModal";
import ConfirmedLight from "../../Components/Confirmed/ConfirmedLight";

type verifyOtpTypes = {
  verification_code: string;
};
const VerifyOtp = () => {
  const navigate = useNavigate();
  const { mobileNumber } = useContext(ContextApiData);
  const verifyOtpToastId = useRef<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Verify Otp Promise
  const verifyOtpHandler: any = async (data: any) => {
    verifyOtpToastId.current = toast("Verifying Otp...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + verify_Mobile_Otp,
      data
    );
    return response.data;
  };
  const initialValues: verifyOtpTypes = {
    verification_code: "",
  };

  const validationSchema = Yup.object({
    verification_code: Yup.string().required("Otp is required"),
  });

  const { mutate }: any = useMutation(verifyOtpHandler, {
    onSuccess: (data) => {
      console.log({ data }, "result of verify otp");
      toast.update(verifyOtpToastId.current, {
        render: "Otp verified Successfully",
        type: "success",
        autoClose: 1000,
      });
      navigate("/");
      setIsModalVisible(true);
    },
    onError: (error) => {
      console.log({ error });
      toast.update(verifyOtpToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });
  const handleSubmit = (values: verifyOtpTypes) => {
    let allValues = {
      ...values,
      mobile: mobileNumber,
    };
    mutate(allValues);
  };

  return (
    <VerifyOtpStyle>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <ConfirmedLight />
      </CustomModal>
      <div className="inner-container">
        <h6 className="title mb-0">Veriﬁcation codes OTP</h6>
        <p className="message">
          A verification codes has been sent to:
          <p className="number d-flex justify-content-center align-items-center mb-0">
            {mobileNumber}
            <span>
              <CustomButton
                bgcolor="transparent"
                color={PrimaryColor}
                padding="4px 8px"
                width="100%"
                type="button"
                title="Edit"
                margin="auto"
                fontSize="14px"
                fontFamily="EnnVisionsMedium"
                clicked={() => navigate("/register-phone")}
                border="none"
              />
            </span>
          </p>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            console.log(formik.values, "values");

            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                // validateMessages={validationSchema}
              >
                <div className="login-input-fields-field mt-2">
                  <FormControl
                    control="input"
                    type="number"
                    name="verification_code"
                    border={`1px solid ${tertiaryGrewish}`}
                    placeholder="_ _ - _ _"
                    className={
                      formik.errors.verification_code &&
                      formik.touched.verification_code
                        ? "is-invalid text-center"
                        : "customPasswordInput text-center"
                    }
                  />
                </div>
                <p className="resend-box-time d-flex justify-content-between align-items-center">
                  59s
                  <span>
                    <CustomButton
                      bgcolor="transparent"
                      color={PrimaryColor}
                      padding="4px 8px"
                      width="100%"
                      type="button"
                      title="RESEND"
                      margin="auto"
                      fontSize="14px"
                      fontFamily="EnnVisionsMedium"
                      border="none"
                      clicked={() => console.log("resend")}
                    />
                  </span>
                </p>
                <div className="mt-3">
                  <CustomButton
                    bgcolor={PrimaryColor}
                    color="white"
                    padding="8px 8px"
                    width="100%"
                    type="submit"
                    title="NEXT"
                    margin="auto"
                    fontSize="16px"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>

        {/* <AuthenticateText
          title="Don't have an account ?"
          link="/register-phone"
          linkText="Sign Up"
          margin="10px 0 0 0"
        /> */}
      </div>
    </VerifyOtpStyle>
  );
};

export default VerifyOtp;
