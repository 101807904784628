import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { MainHeading, PrimaryColor } from "../../Components/GlobalStyle";
import { Helmet } from "react-helmet";
import VerifyByEmailStyle from "./style";
import { useMutation } from "react-query";
import { Base_Url, verify_email__code_url } from "../../utils/api_urls";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";

type verifyByEmailCode = {
  customer_email: string;
  reset_code: string;
  new_password: string;
};

const initialValues: verifyByEmailCode = {
  customer_email: "",
  reset_code: "",
  new_password: "",
};

const validationSchema = Yup.object({
  customer_email: Yup.string()
    .required("Email is required!")
    .email("Email should be valid"),
  reset_code: Yup.string().required("Old Password is required!"),
  new_password: Yup.string().required("New Password is required!"),
});

const ResetEmail = () => {
  const navigate = useNavigate();
  const verifyCodeEmailTaostId = useRef<any>(null);

  // verify code by emai Promise
  const verifyCodeByEmail = async (data: any) => {
    verifyCodeEmailTaostId.current = toast("verifying code...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + verify_email__code_url,
      data
    );
    return response;
  };

  // verify code by emai query
  const { mutate: verifyByEmailCodeMutation, isLoading } = useMutation(
    verifyCodeByEmail,
    {
      onSuccess: (data) => {
        console.log({ data });
        toast.update(verifyCodeEmailTaostId.current, {
          render: "code verified succesfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        navigate("/login");
      },
      onError: ({
        response: {
          data: { message },
        },
      }: any) => {
        toast.update(verifyCodeEmailTaostId.current, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      },
    }
  );
  // verify code otp submission
  const onSubmit = async (values: verifyByEmailCode) => {
    verifyByEmailCodeMutation({
      ...values,
      reset_code: +values.reset_code,
    });
  };

  return (
    <>
      <Helmet>
        <title>Verify Otp By Email</title>
      </Helmet>
      <VerifyByEmailStyle>
        <div className="inner-container-card">
          <div className="mb-2">
            <MainHeading>Verify Otp By Email</MainHeading>
          </div>

          <div className="inner-container-card-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                console.log(formik.values);
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <FormControl
                      control="input"
                      type="text"
                      name="customer_email"
                      label="Email"
                      placeholder="Email Address"
                      fontFamily="EnnVisionsMedium"
                      labelFamily="EnnVisionsMedium"
                      labelMarginBottom="8px"
                      className={
                        formik.errors.customer_email &&
                        formik.touched.customer_email
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                    <div className="mt-2">
                      <FormControl
                        control="input"
                        type="tel"
                        name="reset_code"
                        label="Otp"
                        placeholder="Otp"
                        fontFamily="EnnVisionsMedium"
                        labelMarginBottom="8px"
                        className={
                          formik.errors.reset_code && formik.touched.reset_code
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </div>
                    <div className="mt-2">
                      <FormControl
                        control="password"
                        type="text"
                        name="new_password"
                        label="New Password"
                        placeholder="New Password"
                        fontFamily="EnnVisionsMedium"
                        labelMarginBottom="8px"
                        className={
                          formik.errors.new_password &&
                          formik.touched.new_password
                            ? "is-invalid"
                            : "customPasswordInput"
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="8px"
                        width="100%"
                        type="submit"
                        title="Submit"
                        fontSize="14px"
                        fontFamily="EnnVisionsMedium"
                        disabled={isLoading}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </VerifyByEmailStyle>
    </>
  );
};

export default ResetEmail;
