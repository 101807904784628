import { Formik } from "formik";
import CustomButton from "../CustomButton/CustomButton";
import { MainHeading, PrimaryColor } from "../GlobalStyle";
import RegisterPhoneStyle from "./style";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import CustomInputNumber from "../CustomInputNumber/InputNumber";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Base_Url, send_Otp } from "../../utils/api_urls";
import axios from "axios";
import { Form } from "antd";
import { ContextApiData } from "../../utils/CreateContext";
import { useContext, useRef } from "react";

type registerPhoneTypes = {
  mobile: string;
};
const RegisterPhone = () => {
  const navigate = useNavigate();
  const { setMobileNumber } = useContext(ContextApiData);
  const registerPhoneId = useRef<any>(null);

  const initialValues = {
    mobile: "",
  };

  const validationSchema = Yup.object({
    mobile: Yup.string().required("Mobile is required!"),
  });

  // Verify Otp Promise
  const registerPhoneHandler: any = async (data: any) => {
    console.log({ data });
    registerPhoneId.current = toast("register phone...", {
      type: "info",
    });
    const { data: response } = await axios.post(Base_Url + send_Otp, data);
    return response.data;
  };

  const { mutate }: any = useMutation(registerPhoneHandler, {
    onSuccess: () => {
      toast.update(registerPhoneId.current, {
        render: "Phone Registered Successfully",
        type: "success",
        autoClose: 1000,
      });
      navigate("/verify-otp");
      // setIsModalVisible(true);
    },
    onError: (data: any) => {
      console.log({ data });
      toast.update(registerPhoneId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });
  const handleSubmit = (values: registerPhoneTypes) => {
    const updatedValues = { mobile: "+" + values.mobile };
    setMobileNumber(updatedValues.mobile);
    mutate(updatedValues);
  };

  return (
    <RegisterPhoneStyle>
      <div className="inner-container">
        <MainHeading>Register Phone</MainHeading>
        <p className="message">
          A four digit code will be sent to your phone to verify your account
        </p>
        <div className="mt-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <Form
                  name="basic"
                  onFinish={formik.handleSubmit}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  // validateMessages={validationSchema}
                >
                  <div className="login-input-fields-field mt-2">
                    <CustomInputNumber
                      value={formik.values.mobile}
                      formik={formik}
                      placeholder="e.g 12345678"
                    />
                    {formik.errors.mobile && formik.touched.mobile && (
                      <p className="error-text text-start mt-2">
                        {formik.errors.mobile}
                      </p>
                    )}
                    <div className="mt-3">
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="8px 8px"
                        width="100%"
                        type="submit"
                        title="SUBMIT"
                        margin="auto"
                        fontSize="16px"
                        fontFamily="EnnVisionsMedium"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* <div className="signup-text mt-3">
          <p> Don't have an account ?</p>
          <h6 className="ms-1">
            <Link to="/verify-otp">Sign Up</Link>
          </h6>
        </div> */}
      </div>
    </RegisterPhoneStyle>
  );
};

export default RegisterPhone;
