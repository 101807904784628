import { PrimaryHeading, Primarypara } from "./../../GlobalStyle";
import WristandStyle from "./style";
import locationIcon from "../../../assets/icons/ic_property_detail_map_pin.svg";
import mainImg from "../../../assets/images/BG_Heart_Planet.jpg";
import MapBox from "../../MapBox/MapBox";

const Wristand = () => {
  return (
    <WristandStyle>
      <PrimaryHeading className="mb-4">About Wristand</PrimaryHeading>
      <Primarypara>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
        rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
        ipsum dolor sit ameto their location.
      </Primarypara>
      <div className="wristand__image-container">
        <figure>
          <img src={mainImg} alt="main-img" className="wristand__image" />
        </figure>
      </div>
      <PrimaryHeading>Location</PrimaryHeading>
      <Primarypara>
        sanctus est Lorem ipsum dolor sit ameto their location.
      </Primarypara>
      <div className="pb-3">
        <MapBox width="100%" height="360px" />
      </div>
    </WristandStyle>
  );
};

export default Wristand;
