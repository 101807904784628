import { useState } from "react";
import { PrimaryHeading2 } from "../../GlobalStyle";
import plusicon from "../../../assets/litgalaxyicons/ic_tickets_qty_add.svg";
import minusicon from "../../../assets/litgalaxyicons/ic_tickets_qty_remove.svg";

import QTYStyle from "./style";
import { DeleteOutlined } from "@ant-design/icons";
const QTY = ({ title, number }: { number: number; title: string }) => {
  const [counter, setCounter] = useState(0);
  return (
    <QTYStyle className="d-flex align-items-center gap-2 q-t-y__container flex-lg-row flex-column text-center">
      <PrimaryHeading2>{title}</PrimaryHeading2>
      <p className="mb-0 q-t-y__heading mb-2">QTY: {number}</p>
    </QTYStyle>
  );
};

export default QTY;
