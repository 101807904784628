import styled from "styled-components";
import { tertiaryGrey8 } from "../../Components/GlobalStyle";

export const LoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: ${tertiaryGrey8};
  margin: 0 auto;

  .inner-container {
    &-card {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 6px;
      width: 100%;
      margin: auto;
      transform: translateY(-20%);
      padding: 20px 12px;
      &-form {
        width: 96%;
      }
    }
  }
`;

export default LoginStyle;
