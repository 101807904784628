import { useNavigate } from "react-router-dom";
import ErrorImg from "../../assets/images/error.svg";
import { Button } from "../styles/Button";
import Page404Style from "./style";

const Page404 = () => {
  const navigate = useNavigate();
  const navigateToPreviousPage = () => navigate(-1);
  return (
    <Page404Style>
      <img src={ErrorImg} alt="error" />
      <Button className="btn" onClick={navigateToPreviousPage}>
        {" "}
        Go Back
      </Button>
    </Page404Style>
  );
};

export default Page404;
