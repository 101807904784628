import styled from "styled-components";
import {
  lightGrey,
  lightGrey2,
  PrimaryColor,
  secondaryDark3,
  whiteColor,
} from "../../../Components/GlobalStyle";

const TicketsStyle = styled.div`
  .tickets__container {
    background: ${whiteColor};
    border: 0.5px solid ${lightGrey};
    border-radius: 2px;
  }
  .tickets__primaryheading {
    font-size: 18px;
    font-family: "EnnVisionsMediums";
    color: ${secondaryDark3};
    text-transform: uppercase;
  }
  .tickets__subheading {
    font-size: 13px;
    font-family: "EnnVisionsMedium";
    color: ${lightGrey2};
    text-transform: uppercase;
  }
`;

export const UserDetailsStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  .address-container {
    padding: 10px;
    width: 100%;
    border: 0.5px solid ${lightGrey};
    border-radius: 2px;
    .input__container {
      position: relative;
      margin-top: 10px;

      .edit-icon {
        right: 2%;
        padding: 6px;
        top: 20%;
        &:hover {
          box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
            rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
          top: 20%;
        }
      }

      .form__field {
        font-family: "EnnVisions";
        width: 100%;
        border: 0;
        border: 1px solid #d2d2d2;
        outline: 0;
        font-size: 16px;
        color: #212121;
        padding: 12px 0;
        background: transparent;
        transition: border-color 0.2s;
        padding-left: 10px;

        &::placeholder {
          color: transparent;
        }
        &::after {
          content: "Image Icon";
          background-color: red;
          z-index: 30px;
        }
      }

      .form__field:placeholder-shown ~ .form__label {
        font-size: 16px;
        cursor: text;
        top: 20%;
        padding: 0 10px;
      }

      label,
      .form__field:focus ~ .form__label {
        position: absolute;
        top: -20%;
        display: block;
        transition: 0.2s;
        font-size: 12px;
        color: #9b9b9b;
        background: white;
        margin-left: 12px;
        padding-left: 6px;
        padding-right: 6px;
      }

      .form__field:focus ~ .form__label {
        color: ${PrimaryColor};
        margin-left: 10px;
      }

      .form__field:focus {
        /* padding-bottom: 6px; */
        &:focus {
          border: 1px solid ${PrimaryColor};
          padding-left: 10px;
          padding-right: 10px;
          /* margin-top: 8px; */
          z-index: 6;
        }
      }
    }
  }
`;
export const CreditCardStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;

  .inner-container {
    background: ${whiteColor};
    padding: 12px;
    border: 0.5px solid ${lightGrey};

    .expiry__date {
      label {
        font-family: "EnnVisionsMedium";
      }
      &__section {
        &__inputs {
          border: 1px solid #d9d9d9;
          padding: 3px;
          border-radius: 5px;
          margin-top: 9px;
          input {
            width: 30px;
          }
          .slash {
            font-size: 18px;
          }
        }
      }
    }
  }
  .is-invalid {
    margin-top: 7px;
    color: ${PrimaryColor};
  }
`;
export const ShippingDetailsStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  .inner-container {
    padding: 12px;
    background: ${whiteColor};
    border: 0.5px solid ${lightGrey};
  }
`;

export default TicketsStyle;
