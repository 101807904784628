import { Form } from "antd";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import FormController from "../../../Components/FormControl";
import * as Yup from "yup";
import { CreditCardStyle } from "./style";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { PrimaryColor, TertiaryHeading } from "../../../Components/GlobalStyle";
import { useNavigate } from "react-router-dom";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useRef, useState } from "react";
import valid from "card-validator";
import { toast } from "react-toastify";
import { LoggedInHandler } from "../../../ProtectedRoute";
import axios from "axios";
import { useMutation } from "react-query";
import { Base_Url, credit_card_url } from "../../../utils/api_urls";
import CustomMaskInput from "../../../Components/MaskInput/MaskInput";
import InputMask from "react-input-mask";

type createCreditCardTypes = {
  holder_name: string;
  card_number: string;
  expiry_month: string;
  expiry_year: string;
  cvc: string;
  is_default: boolean;
};

// "postal_code": "15A 8C5",
// "is_default": false
const initialValues: createCreditCardTypes = {
  holder_name: "",
  card_number: "",
  expiry_month: "",
  expiry_year: "",
  cvc: "",
  is_default: false,
};

const validationSchema = Yup.object().shape({
  card_number: Yup.string().test(
    "test-number",
    "credit card number is invalid",
    (value) => valid.number(value).isValid
  ),
  holder_name: Yup.string().test(
    "test-number",
    "owner name is invalid",
    (value) => valid.cardholderName(value).isValid
  ),
  expiry_month: Yup.string().test(
    "test-number",
    "month is invalid",
    (value) => valid.expirationMonth(value).isValid
  ),
  expiry_year: Yup.string().test(
    "test-number",
    "year is invalid",
    (value) => valid.expirationYear(value).isValid
  ),
  cvc: Yup.string().test(
    "test-number",
    "cvc is invalid",
    (value) => valid.cvv(value).isValid
  ),
});

// const validationSchema = Yup.object({
//   holder_name: Yup.string().required(" name is required !"),
//   card_number: Yup.string().required("card number is required !"),
//   expiry: Yup.string().required("card expiry is required !"),
//   cvc: Yup.string().required("cvc is required !"),
// });

const CreateCreditCard = () => {
  const [focus, setFocus] = useState<any>("");
  const createCardToastId = useRef<any>(null);
  const navigate = useNavigate();
  // create credit card promise
  const createCartHandler = async (data: any) => {
    createCardToastId.current = toast("credit card adding...", {
      type: "info",
    });
    const { data: response } = await axios.post(
      Base_Url + credit_card_url,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // create credit card mutation
  const { mutate } = useMutation(createCartHandler, {
    onSuccess: (data) => {
      toast.update(createCardToastId.current, {
        render: "credit card added successfully",
        type: "success",
        autoClose: 1000,
      });
      navigate("/order-summary");
    },
    onError: ({ response: { data } }: any) => {
      toast.update(createCardToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  const onSubmit = (values: createCreditCardTypes) => {
    const allValues = {
      ...values,
      postal_code: "15A 8C5",
      expiry_month: parseInt(values.expiry_month),
    };
    mutate(allValues);
  };

  return (
    <CreditCardStyle>
      <TertiaryHeading>Credit Card Details</TertiaryHeading>
      <div className="inner-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form
                style={{
                  height: "100%",
                }}
                name="basic"
                onFinish={formik.handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Cards
                  cvc={formik.values.cvc}
                  name={formik.values.holder_name}
                  number={formik.values.card_number}
                  expiry={
                    formik.values.expiry_month
                    // +
                    // (formik.values.expiry_year.length > 0
                    //   ? formik.values.expiry_year.substring(2, 4)
                    //   : "")
                  }
                  focused={focus}
                />

                <div className="mt-3">
                  <FormController
                    control="input"
                    fontFamily="EnnVisionsMedium"
                    labelFamily="EnnVisionsMedium"
                    padding="12px"
                    label="Name on Card"
                    type="text"
                    name="holder_name"
                    placeholder="Name on Card"
                    className={
                      formik.errors.holder_name && formik.touched.holder_name
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </div>
                <div className="mt-2">
                  <CustomMaskInput
                    type="tel"
                    mask="9999 9999 9999 9999"
                    maskChar=""
                    placeholder="____ ____ ____ ____"
                    name="card_number"
                    label="Card Number"
                    setValue={(value) =>
                      formik.setFieldValue(
                        "card_number",
                        value.split(" ").join(",").replaceAll(/,/g, "")
                      )
                    }
                  />
                  {formik.errors.card_number && formik.touched.card_number && (
                    <p className="is-invalid">{formik.errors.card_number}</p>
                  )}
                </div>
                <Row className="expiry__date">
                  <Col md={6} className="mt-2 expiry__date__section">
                    <label htmlFor="expiry">Expiration Date</label>
                    <div
                      className="d-flex expiry__date__section__inputs"
                      id="expiry"
                    >
                      <InputMask
                        type="tel"
                        name="expiry_month"
                        mask="99"
                        placeholder="__"
                        onChange={(e) =>
                          formik.setFieldValue("expiry_month", e.target.value)
                        }
                        maskChar="_"
                      />

                      <p className="mb-0 mt-1 me-2 slash">/</p>
                      <InputMask
                        type="tel"
                        name="expiry_year"
                        mask="9999"
                        placeholder="____"
                        onChange={(e) =>
                          formik.setFieldValue("expiry_year", e.target.value)
                        }
                        maskChar="_"
                      />
                    </div>
                    <div className="d-flex">
                      <div className="me-3">
                        {formik.errors.expiry_month &&
                          formik.touched.expiry_month && (
                            <p className="is-invalid">
                              {formik.errors.expiry_month}
                            </p>
                          )}
                      </div>
                      {formik.errors.expiry_year &&
                        formik.touched.expiry_year && (
                          <p className="is-invalid">
                            {formik.errors.expiry_year}
                          </p>
                        )}
                    </div>
                  </Col>
                  <Col md={6} className="mt-2">
                    <CustomMaskInput
                      type="tel"
                      mask="999"
                      maskChar="_"
                      name="cvv"
                      setValue={(value) => formik.setFieldValue("cvc", value)}
                      placeholder="___"
                      label="Security Code"
                    />
                    {formik.errors.cvc && formik.touched.cvc && (
                      <p className="is-invalid">{formik.errors.cvc}</p>
                    )}
                  </Col>
                </Row>
                <div className="remember-password d-flex mb-2 mt-3 align-items-center cursor-pointer">
                  <input
                    value={formik.values.is_default as any}
                    onChange={() =>
                      formik.setFieldValue(
                        "is_default",
                        !formik.values.is_default
                      )
                    }
                    type="checkbox"
                    name="is_default"
                    id="is_default"
                  />
                  {/* <p className="remember-password-text mb-0 ms-2">
                          Remember me
                        </p> */}
                  <label
                    htmlFor="is_default"
                    className="remember-password-text mb-0 ms-2 cursor-pointer"
                    style={{ color: PrimaryColor }}
                  >
                    Set as default payment method
                  </label>
                </div>
                <div className="mt-2 d-flex justify-content-center justify-content-sm-end">
                  <CustomButton
                    bgcolor={PrimaryColor}
                    color="white"
                    padding="8px 8px"
                    width="200px"
                    type="submit"
                    title="Submit"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CreditCardStyle>
  );
};

export default CreateCreditCard;
