import {
  PrimaryHeading,
  Primarypara,
  Tertiaryheading,
} from "./../../GlobalStyle";
import AbouteventStyle from "./style";

const Aboutevent = () => {
  const AbouteventData = [{ title: "" }, { title: "" }, { title: "" }];
  return (
    <AbouteventStyle className="mt-3">
      <PrimaryHeading>About Event</PrimaryHeading>
      {AbouteventData.map((_, index) => {
        return (
          <Primarypara
            boldFamily={true}
            key={index}
            className="about__headings"
          >
            <span>3-Day GA - $449CA </span>
            includes general admission, camping/all-day access to campground and
            parking.
          </Primarypara>
        );
      })}
      <Primarypara>
        Ticket Policy:Tickets are non-transferable and non-refundable Wristbands
        will be mailed 4 - 6 weeks before the venue
      </Primarypara>
      <h6 className="about__subheading ">LitGalaxy</h6>
      <Primarypara>
        556210 Mulmur Melancthon Townline, Melancthon, ON Canada
      </Primarypara>
      <Tertiaryheading className="about__tertiaryheading">
        Hours:
      </Tertiaryheading>
      <Primarypara>Arrival time to campsite begins Friday 11 am</Primarypara>
      <Primarypara className="about__hours-timing">
        <span>Friday, 1 July 2023,</span>
        <span>4:00 PM – 2:00 AM EST</span>
        <span>Saturday, 2 July 2023,</span>
        <span>10:00 AM – 2:00 AM EST</span>
        <span>Sunday, 3 July 2023,</span>
        <span>10:00 AM – 11:00 PM EST</span>
      </Primarypara>
      <Tertiaryheading className="about__secondaryheading">
        Activities and Amenities
      </Tertiaryheading>
      <Primarypara>
        Wide variety of food vendors - something for everyone Retail vendors -
        everything from jewelry, clothing, crafts and more Water Refill Stations
        - bring a reusable water bottle (no glass) or buy one on site Group yoga
        and meditation - multiple sessions throughout the weekend Hula hooping
        sessions, Dance lessons, Rock climbing wall, Games
      </Primarypara>
      <Tertiaryheading className="about__primaryheading">
        Activities and Amenities
      </Tertiaryheading>
      <Primarypara>
        Volunteers, Sponsorships and partners, Food vendors, Retail /craft
        vendors, Visual artist installation, Artists/performers, Activities and
        workshops
      </Primarypara>
    </AbouteventStyle>
  );
};

export default Aboutevent;
