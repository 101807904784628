import styled from "styled-components";
import { pureDark, lightGrey } from "./../../GlobalStyle";

const QTYStyle = styled.div`
  .q-t-y__heading,
  .q-t-y__num {
    text-align: left;
    font-size: 20px;
    font-family: "EnnVisionsMedium";
    letter-spacing: 0.12px;
    color: ${pureDark};
    line-height: 18px;
    border-left: 1px solid ${lightGrey};
    padding-left: 6px;
  }
`;
export default QTYStyle;
