import { Form } from "antd";
import { ErrorMessage, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import FormController from "../../../Components/FormControl";
import { PrimaryColor, TertiaryHeading } from "../../../Components/GlobalStyle";
import CreateShippingAddressesStyle from "./style";
import rigtSideImg from "../../../assets/litgalaxyicons/main-img.jpg";
import CustomInputNumber from "../../../Components/CustomInputNumber/InputNumber";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Base_Url,
  shipping_addresses_url,
  shipping_address_details_url,
  update_shipping_address_url,
} from "../../../utils/api_urls";
import { useMutation, useQuery } from "react-query";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { useRef } from "react";
import { axiosRequest } from "../../../utils/axios.utils";
import Loader from "../../../Components/Loader/Loader";
import warningImg from "../../../assets/images/warning.png";

const UpdateShippingAddress: any = () => {
  const updateShippingAddressToastId = useRef<any>(null);
  const shippingAddressDetailsToastId = useRef<any>(null);
  const { addressId } = useParams();
  const navigate = useNavigate();

  type updateShippingAddressesTypes = {
    first_name: string;
    last_name: string;
    address_line_1: string;
    phone_number: string;
    aptNumber?: string;
  };

  // Validation Schema
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address_line_1: Yup.string().required("Address is required"),
    phone_number: Yup.string().required("Phone is required"),
    aptNumber: Yup.string().required("Apartment No. is required"),
  });

  // shipping address details promise
  const getShippingAddressHandler = async () => {
    shippingAddressDetailsToastId.current = toast(
      "getting shipping address...",
      {
        type: "info",
      }
    );
    const { data: response } = await axiosRequest({
      url: shipping_address_details_url + addressId + "/detail",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // update shopping address promise
  const updateShippingAddressHandler = async (data: any) => {
    updateShippingAddressToastId.current = toast("sending address data...", {
      type: "info",
    });
    const { data: response } = await axios.put(
      Base_Url + update_shipping_address_url + addressId + "/update",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
        },
      }
    );
    return response.data;
  };

  // get shipping address details query
  const { data, isLoading, isFetching, isError }: any = useQuery(
    ["shipping-address-details", addressId],
    getShippingAddressHandler,
    {
      onSuccess: (data) => {
        console.log({ data });

        toast.update(shippingAddressDetailsToastId.current, {
          render: "got addresses successfully",
          type: "success",
          autoClose: 1000,
        });
      },
      onError: () => {
        toast.update(shippingAddressDetailsToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      },
      staleTime: 1000,
      cacheTime: 1000,
    }
  );

  // if (isLoading || isFetching) return <Loader />;
  // if (isError)
  //   return (
  //     <article>
  //       <img src={warningImg} alt="error" />
  //       <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
  //     </article>
  //   );

  // update shipping address query

  const initialValues = {
    first_name: !isLoading && !isError ? data.first_name : "",
    last_name: !isLoading && !isError ? data.last_name : "",
    address_line_1:
      !isLoading &&
      !isError &&
      data.address_line_1 + (!isLoading && !isError) &&
      data.address_line_2,
    phone_number: !isLoading && !isError ? data.phone_number : "",
    aptNumber: !isLoading && !isError ? data.postal_code : "",
  };

  const { mutate } = useMutation(updateShippingAddressHandler, {
    onSuccess: (data) => {
      toast.update(updateShippingAddressToastId.current, {
        render: "address updated successfully",
        type: "success",
        autoClose: 1000,
      });
      console.log(data);
      navigate("/order-summary");
    },
    onError: ({ response: { data } }: any) => {
      console.log({ data });
      if (data.message === "Unauthenticated.") {
        toast.update(updateShippingAddressToastId.current, {
          render: "unauthnticated",
          type: "error",
          autoClose: 1000,
        });
      } else {
        toast.update(updateShippingAddressToastId.current, {
          render: "something went wrong",
          type: "error",
          autoClose: 1000,
        });
      }
    },
  });

  const onSubmit = (values: updateShippingAddressesTypes) => {
    delete values.aptNumber;

    const newValues = {
      email: "shafqat13000@test.com",
      address_line_2: "Drive",
      country_id: 1,
      state_id: 1,
      city_id: 1,
      postal_code: "3045",
      lat: 37.4281350802915,
      lng: -122.0792542197085,
      created_at: "",
      updated_at: "",
      ...values,
    };
    mutate(newValues);
  };

  return (
    <CreateShippingAddressesStyle className="row align-items-center">
      <Col md={8}>
        <TertiaryHeading>Personal Info</TertiaryHeading>
        {isError ? (
          <h2>something went wrong</h2>
        ) : isLoading ? (
          <Loader />
        ) : (
          <div className="inner-container mt-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form
                    style={{
                      height: "100%",
                    }}
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row>
                      <Col md={6} className="mt-2">
                        <FormController
                          control="input"
                          fontFamily="EnnVisionsMedium"
                          labelFamily="EnnVisionsMedium"
                          padding="12px"
                          label="First Name"
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          className={
                            formik.errors.first_name &&
                            formik.touched.first_name
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                      <Col md={6} className="mt-2">
                        <FormController
                          control="input"
                          fontFamily="EnnVisionsMedium"
                          labelFamily="EnnVisionsMedium"
                          padding="12px"
                          label="Last Name"
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          className={
                            formik.errors.last_name && formik.touched.last_name
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                    </Row>
                    <TertiaryHeading className="mt-3">
                      Main Shipping Address
                    </TertiaryHeading>
                    <div className="inner-container mt-3">
                      <div className="mt-2">
                        <FormController
                          control="input"
                          fontFamily="EnnVisionsMedium"
                          labelFamily="EnnVisionsMedium"
                          padding="12px"
                          label="Enter Complete Address"
                          type="text"
                          name="address_line_1"
                          placeholder="Enter Complete Address"
                          className={
                            formik.errors.address_line_1 &&
                            formik.touched.address_line_1
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </div>
                      <Row>
                        <Col md={6} className="mt-2">
                          <FormController
                            control="input"
                            fontFamily="EnnVisionsMedium"
                            labelFamily="EnnVisionsMedium"
                            padding="12px"
                            label="Apt no./unit"
                            type="text"
                            name="aptNumber"
                            placeholder="Apt no./unit"
                            className={
                              formik.errors.aptNumber &&
                              formik.touched.aptNumber
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                        </Col>
                        <Col md={6} className="mt-2">
                          <CustomInputNumber
                            padding="7px"
                            value={formik.values.phone_number}
                            formik={formik}
                            showLabel={true}
                            name="phone_number"
                            placeholder="Enter Phone ..."
                            marginTop="4px"
                            label="Phone Number"
                          />
                          {/* <ErrorMessage name="phone" component={<p></p>}/> */}
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-2 d-flex justify-content-center justify-content-sm-end">
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="8px 8px"
                        width="250px"
                        type="submit"
                        title="SAVE ADDRESS"
                        margin="auto"
                        fontFamily="EnnVisionsMedium"
                        fontSize="16px"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </Col>
      <Col md={4} className=" my-3 mx-auto d-flex">
        <figure className="mb-3 mx-auto">
          <img src={rigtSideImg} alt="event-img" />
        </figure>
      </Col>
    </CreateShippingAddressesStyle>
  );
};

export default UpdateShippingAddress;
