import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../assets/icons/ic_edit.svg";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { PrimaryColor, TertiaryHeading } from "../../../Components/GlobalStyle";
import { UserDetailsStyle } from "./style";

const UserDetails = () => {
  const navigate = useNavigate();
  return (
    <UserDetailsStyle>
      <TertiaryHeading>Details</TertiaryHeading>
      <div className="address-container">
        <Container>
          <Row>
            <Col md={6} className="p-0">
              <div className="input__container">
                <input
                  type="text"
                  id="firstName"
                  className="form__field"
                  placeholder="First Name"
                />
                <label htmlFor="firstName" className="form__label">
                  First Name
                </label>
              </div>
            </Col>

            <Col md={6} className="p-0 ps-md-3">
              <div className="input__container">
                <input
                  type="text"
                  id="lastName"
                  className="form__field"
                  placeholder="Last Name"
                />
                <label htmlFor="lastName" className="form__label">
                  Last Name
                </label>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="input__container">
          <input
            type="email"
            id="email"
            className="form__field"
            placeholder="Email Address"
          />

          <label htmlFor="email" className="form__label">
            Email Address
          </label>
          <img
            className="position-absolute edit-icon cursor-pointer"
            src={editIcon}
            alt="edit-icon"
          />
        </div>
      </div>
      <div className="mt-2 d-flex justify-content-sm-end justify-content-center">
        <CustomButton
          bgcolor={PrimaryColor}
          color="white"
          padding="8px 8px"
          width="200px"
          type="submit"
          title="Login"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => navigate("/credit-card")}
        />
      </div>
    </UserDetailsStyle>
  );
};

export default UserDetails;
