import { PrimaryHeading2 } from "../../../Components/GlobalStyle";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Ticketinfo from "../../../Components/litgalaxys/ticketinfo/Ticketinfo";
import PaymentSummary from "../../../Components/litgalaxys/PaymentSummary/PaymentSummary";
import CreditCardInfo from "../../../Components/litgalaxys/CreditCardInfo/CreditCardInfo";
import { useRef } from "react";
import {
  Base_Url,
  order_summary_url,
  update_quantity,
} from "../../../utils/api_urls";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
import { LoggedInHandler } from "../../../ProtectedRoute";
import { useNavigate } from "react-router-dom";
import TicketInfoStyle from "../../../Components/litgalaxys/ticketinfo/style";
import editicon from "../../../assets/litgalaxyicons/ic_edit.svg";
import { axiosRequest } from "../../../utils/axios.utils";
import Loader from "../../../Components/Loader/Loader";
import warningImg from "../../../assets/images/warning.png";
import { cart_item_types } from "../UserCart/UserCart";
import QTY from "../../../Components/litgalaxys/QTY/QTY";
import NoDataFound from "../UserShippingAddresses/NoDataFound";

export type paymentSummaryTypes = {
  deliveryFee: number;
  subTotal: number;
  tax: number;
  serviceFee: number;
  grandTotal: number;
  paymentBreakdown: {
    categoryName: string;
    subTotal: number;
    wristBandQuantity: number;
  }[];
};

export type userCreditCardTypes = {
  id: number;
  holder_name: string;
  postal_code: string;
  expiry_month: number;
  expiry_year: number;
  brand: string;
  last4: string;
  is_default: number;
  user_id: number;
  created_at: "2023-01-23T16:03:18.000000Z";
  updated_at: "2023-01-23T16:03:18.000000Z";
  cartNumber: "**** **** **** 4242";
};
export type orderSummaryTypes = {
  cartSummary: cart_item_types[];
  paymentSummary: paymentSummaryTypes;
  userCreditCard: userCreditCardTypes[];
};

const OrderSummary = () => {
  const updateQuantityToastId = useRef<any>(null);
  const navigate = useNavigate();
  const orderSummaryToastId = useRef<any>(null);

  // update cart quantity

  // update cart quantity promise
  // const createCartHandler = async (data: any) => {
  //   updateQuantityToastId.current = toast("cart adding...", {
  //     type: "info",
  //   });
  //   const { data: response } = await axios.post(
  //     Base_Url + update_quantity,
  //     data,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
  //       },
  //     }
  //   );
  //   return response.data;
  // };

  // update cart quantity mutation
  // const { mutate: updateCartQuantityMutate } = useMutation(createCartHandler, {
  //   onSuccess: (data) => {
  //     toast.update(updateQuantityToastId.current, {
  //       render: "cart added successfully",
  //       type: "success",
  //       autoClose: 1000,
  //     });
  //     navigate("/order-summary");
  //   },
  //   onError: ({ response: { data } }: any) => {
  //     toast.update(updateQuantityToastId.current, {
  //       render: "something went wrong",
  //       type: "error",
  //       autoClose: 1000,
  //     });
  //   },
  // });

  //update cart quantity handler
  // const handleUpdateQuantity = () => {
  //   updateCartQuantityMutate({
  //     id: 21,
  //     quantity: 2,
  //   });
  // };

  // order summary promise
  const getTicketsHandler = async () => {
    orderSummaryToastId.current = toast("get summary...", {
      type: "info",
    });
    const { data: response } = await axiosRequest({
      url: order_summary_url,
      headers: {
        Authorization: `Bearer ${LoggedInHandler() && LoggedInHandler()}`,
      },
    });
    return response.data;
  };

  // order summary query
  const {
    data: orderSummaryData,
    isLoading,
    isError,
    isFetching,
  } = useQuery<orderSummaryTypes>("order-summary-data", getTicketsHandler, {
    onSuccess: (data) => {
      toast.update(orderSummaryToastId.current, {
        render: "got summary successfully",
        type: "success",
        autoClose: 1000,
      });
    },
    refetchOnWindowFocus: false,
    onError: () => {
      toast.update(orderSummaryToastId.current, {
        render: "something went wrong",
        type: "error",
        autoClose: 1000,
      });
    },
  });

  if (isLoading || isFetching) return <Loader />;
  if (isError)
    return (
      <article>
        <img src={warningImg} alt="error" />
        <p className="text-center fs-5 mt-2 fw-bold">something went wrong</p>
      </article>
    );
  // if (orderSummaryData && orderSummaryData?.cartSummary.length < 1) {
  //   return <NoDataFound title="No Data Available" />;
  // }
  return (
    <>
      <Row className="flex-wrap-reverse">
        <Col md={8}>
          {orderSummaryData && orderSummaryData?.cartSummary.length < 1 ? (
            <NoDataFound title="Order summary is not available" />
          ) : (
            <>
              <PrimaryHeading2>Order Summary</PrimaryHeading2>
              <div className="mt-4">
                {orderSummaryData?.cartSummary.map(
                  ({
                    cartItems,
                    description,
                    id,
                    quantity,
                    name,
                    retail_price,
                  }: cart_item_types) => (
                    <div
                      key={id}
                      className={`${
                        cartItems.length === 0 ? "d-none" : "d-block"
                      }`}
                    >
                      <QTY number={quantity} title={name} />
                      {cartItems &&
                        cartItems.map((data) => (
                          <Ticketinfo
                            isEdit={false}
                            isDelete={false}
                            description={description}
                            price={retail_price}
                            name={name}
                            key={data.id}
                            cart_item_data={data}
                          />
                        ))}
                      {/* <Ticketinfo />
            <QTY number={2} title="VIP" />
            <Ticketinfo />
            <QTY number={3} title="VVIP" />
            <Ticketinfo /> */}
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </Col>
        <Col md={4} className="my-3">
          <PaymentSummary data={orderSummaryData?.paymentSummary} />
          <CreditCardInfo
            data={orderSummaryData?.userCreditCard}
            cartSummary={orderSummaryData?.cartSummary}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrderSummary;
