import axios, { AxiosRequestConfig } from "axios";
import { LoggedInHandler } from "../ProtectedRoute";
import { content_Type_Header } from "./api_urls";

const baseURL = "https://api.lgf2000.com/api/";

const client = axios.create({ baseURL });
client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const authorizedClient = axios.create({ baseURL });

type axiosRequest = AxiosRequestConfig;
export const axiosRequest = ({ ...options }: axiosRequest) => {
  console.log({ options });
  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    // optionaly catch errors and add additional logging here
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};
// client.defaults.headers.common['Accept']='application/json';

export const authorizedAxiosRequest = ({ ...options }: axiosRequest) => {
  console.log({ options });
  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    // optionaly catch errors and add additional logging here
    return error;
  };
  return authorizedClient(options).then(onSuccess).catch(onError);
};

authorizedClient.defaults.headers.common["Authorization"] = `Bearer ${
  LoggedInHandler() && LoggedInHandler()
}`;
authorizedClient.defaults.headers.common["Content-Type"] = content_Type_Header;
authorizedClient.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
