import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { MainHeading, PrimaryColor } from "../../Components/GlobalStyle";
import { Helmet } from "react-helmet";
import VerifyByEmailStyle from "./style";
import { useMutation } from "react-query";
import { Base_Url, reset_email_url } from "../../utils/api_urls";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";

type resetEmailTypes = {
  customer_email: string;
};

const initialValues: resetEmailTypes = {
  customer_email: "",
};

const validationSchema = Yup.object({
  customer_email: Yup.string()
    .required("Email is required!")
    .email("Email should be valid"),
});

const ResetEmail = () => {
  const navigate = useNavigate();
  const resetEmailTaostId = useRef<any>(null);

  // reset email promise
  const resetEmailPromise = async (data: any) => {
    resetEmailTaostId.current = toast("verifying email...", {
      type: "info",
      autoClose: false,
    });
    const { data: response } = await axios.post(
      Base_Url + reset_email_url,
      data
    );
    return response.data;
  };

  // reset email query
  const { mutate: resetEmailMutation, isLoading } = useMutation(
    resetEmailPromise,
    {
      onSuccess: (data) => {
        console.log({ data });
        toast.update(resetEmailTaostId.current, {
          render: "sended email Succesfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        navigate("/verify-email-code");
      },
      onError: ({ response: { data } }: any) => {
        alert("in error reset email");
        console.log({ data });
        toast.update(resetEmailTaostId.current, {
          render: data.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      },
    }
  );

  // reset email submit
  const onSubmit = async (values: any) => {
    delete values.terms;
    await resetEmailMutation(values);
  };

  return (
    <>
      <Helmet>
        <title>Reset Email</title>
      </Helmet>
      {/* <span className="arrow-box">
        <img src={scroll_up} alt="scroll-up" />
      </span> */}
      <VerifyByEmailStyle>
        <div className="inner-container-card">
          <div className="mb-2">
            <MainHeading>Reset Email</MainHeading>
          </div>

          <div className="inner-container-card-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                console.log(formik.values);
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <FormControl
                      control="input"
                      type="text"
                      name="customer_email"
                      label="Email"
                      placeholder="Email Address"
                      fontFamily="EnnVisionsMedium"
                      labelFamily="EnnVisionsMedium"
                      labelMarginBottom="8px"
                      className={
                        formik.errors.customer_email &&
                        formik.touched.customer_email
                          ? "is-invalid"
                          : "customInput"
                      }
                    />

                    <div className="mt-3">
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="8px"
                        width="100%"
                        type="submit"
                        title="Confirm"
                        fontSize="14px"
                        fontFamily="EnnVisionsMedium"
                        disabled={isLoading}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </VerifyByEmailStyle>
    </>
  );
};

export default ResetEmail;
