import styled from "styled-components";
import { lightGrey, lightGrey2, whiteColor } from "../../GlobalStyle";

const OrderSummaryStyle = styled.div`
  .summary__container {
    background: ${whiteColor};
    border: 0.5px solid ${lightGrey};
    padding: 50px;
    border-radius: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: auto;
    @media (max-width: 768px) {
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px;
    }
  }
  p {
    text-align: left;
    font-size: 13px;
    /* font-size:18px; */
    line-height: 1.4;
    font-family: "EnnVisionsMedium";
    color: ${lightGrey2};
    span {
      display: block;
    }
  }
  .summary__subheading {
    font-family: "EnnVisionsMedium";
    font-size: 14px;
    /* font-size: 18px; */
    font-weight: bold;
    letter-spacing: 0px;
    color: #464e5f;
  }
`;
export default OrderSummaryStyle;
