import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { PrimaryColor } from "../../../Components/GlobalStyle";
import EarlyBirdTickets from "../../../Components/litgalaxys/EarlybIrdtIckets/EarlybIrdtIckets";
import OrderSummary from "../../../Components/litgalaxys/OrderSummary/Ordersummary";
import TicketsStyle from "./style";

const data = [
  {
    heading: "General Admission / (EARLY BIRD TICKETS)",
    sales_end: "Event Start Friday, 1 July 2023 4:00 PM",
    title: "Stage VIP only bars, quick access",
    tickets: 0,
    selected: true,
    price: 449,
  },
  {
    heading: "VIP / (EARLY BIRD TICKETS)",
    sales_end: "Event Start Friday, 1 July 2023 4:00 PM",
    title: "Stage VIP only bars, quick access",
    tickets: 0,
    selected: false,
    price: 799,
  },
  {
    heading: "VVIP / (EARLY BIRD TICKETS)",
    sales_end: "Event Start Friday, 1 July 2023 4:00 PM",
    title: "Stage VIP only bars, quick access",
    tickets: 0,
    selected: false,
    price: 1499,
  },
];

const Tickets = () => {
  const [ticketsData, setTicketsData] = useState(data);
  const navigate = useNavigate();
  const addTicketsHandler = (index: number, action: string) => {
    const data = [...ticketsData];
    if (action === "add") {
      data[index].tickets += 1;
    } else {
      if (data[index].tickets > 0) {
        data[index].tickets -= 1;
      }
    }
    setTicketsData(data);
  };

  const selectedCardHandler = (index: number) => {
    const data = [...ticketsData];
    const selectedIndex = data.findIndex(({ selected }) => selected === true);
    if (selectedIndex === index) {
      return;
    }
    const updatedData = data.map((item) =>
      item.selected === true ? { ...item, selected: false } : item
    );
    updatedData[index].selected = !data[index].selected;
    setTicketsData(updatedData);
  };

  return (
    <TicketsStyle className="pb-3">
      <Container>
        <Row>
          <Col md={7}>
            <div className="tickets__container px-3 my-2">
              <h1 className="tickets__primaryheading pt-2 text-center mt-3">
                Lit Galaxy Festival
              </h1>
              <p className="tickets__subheading text-center">
                FRIDAY, 1 JULY 2023 4:00 PM – SUNDAY, 3 JULY 2023 11:00 PM EST
              </p>
              {ticketsData.map((ticketsData, index) => {
                return (
                  <EarlyBirdTickets
                    key={index}
                    index={index}
                    ticketsData={ticketsData}
                    addTicketsHandler={addTicketsHandler}
                    selectedCardHandler={selectedCardHandler}
                  />
                );
              })}
            </div>
            <OrderSummary />
            <div className="d-flex justify-content-center justify-content-md-end mt-2">
              <CustomButton
                bgcolor={PrimaryColor}
                color="white"
                padding="8px 8px"
                width="200px"
                type="submit"
                title="Next"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() => navigate("/user-details")}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </TicketsStyle>
  );
};

export default Tickets;
